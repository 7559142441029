import React, { useEffect } from "react";
import { EyeFilled } from '@ant-design/icons';
import { Row, Col, Button, Input, Checkbox, Divider, Segmented} from "antd"; // Importamos todos los componentes necesarios
import {useNavigate } from "react-router-dom";
import { EnvironmentOutlined } from "@ant-design/icons"; // Importa los íconos si es necesario
import Header from "../../../components/pages/principalNav/HeaderOffers.tsx";
import "../../../styles/pages/offers/estilos.css";
import InputC from "../../../components/pages/offers/CreateOffers/Input.tsx";
import Select from "../../../components/pages/offers/CreateOffers/Select.tsx";
import Sliders from "../../../components/pages/offers/CreateOffers/Slider.tsx";
import CustomButton from "../../../components/pages/offers/CreateOffers/Buttons.tsx";
import CustomTag from "../../../components/pages/offers/CreateOffers/CustomTag.tsx";
import '../../../components/styles/pages/principalNav/PrincipalNav.css';
import CheckboxC from "../../../components/checkbox/CheckboxProps.tsx";

const CreateOffer: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate(); 

  type Align = "start" | "center" | "end";
  const [alignValue, setAlignValue] = React.useState<Align>("center");
  
  const handleClose = (text: string) => {
    console.log(`Closed tag: ${text}`);
    // Lógica para manejar el cierre de tags, si es necesario
  };

  return (
    <>
      <Header />
      <div className="bg-white px-[24px] pt-[8px]">
        <h1 className="font-bold text-heading-md pt-[16px]">
          Creación de nueva oferta de empleo
          <Divider className=" bg-grays"></Divider>
        </h1>

        {/* Puesto de trabajo */}
        <Row gutter={24} className="mb-[40px]">
          <Col span={9} className="mr-[0px]">
            <div className="w-[478px]">
              <h3 className="font-medium text-heading-x1 mb-[8px]">
                Puesto de trabajo
              </h3>
              <InputC
                customClassName="h-[44px]"
                placeholder="Escribe las primeras letras del cargo..."
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="">
              <h3 className="font-medium text-heading-x1 mb-[8px] ">
                Elige entre estos posibles sinónimos
              </h3>
              <div className="flex space-x-2">
                <CustomButton text="Cajero" />
                <CustomButton text="Cajero de supermercado" />
                <CustomButton text="Reponedor" />
              </div>
            </div>
          </Col>
        </Row>

        {/* Lugar del puesto de trabajo */}
        <Row gutter={24} className="mb-[40px]">
          <Col span={9} className="">
            <div>
              <h3 className="font-medium text-heading-x1 mb-[8px]">
                Lugar del puesto de trabajo
              </h3>
              <Row gutter={24} className="">
                <Col span={12}>
                  <InputC
                    customClassName="h-[44px]"
                    placeholder="Provincia..."
                    prefix={<EnvironmentOutlined />}
                  />
                </Col>
                <Col span={12}>
                  <InputC
                    customClassName="h-[44px]"
                    placeholder="Población"
                    prefix={<EnvironmentOutlined />}
                  />
                </Col>
              </Row>
            </div>
          </Col>

          <Col span={6}>
            <div className="flex items-center pt-[35px] ml-[11px]">
              {" "}
                {/* Añadido para alinear horizontalmente */}
                <CheckboxC/>
                <h1 className="font-medium text-body-sm text-gray">
                    En remoto
                  </h1>
                <Button className="bg-blue3 text-white w-[102px] ml-[18px] principal-nav-notify-button2 rounded-[4px] ">
                  Buscar
                </Button>
             
            </div>
          </Col>
        </Row>

        {/* Compromiso de transparencia salarial */}
        <Row gutter={24} className="mb-[10px]">
          <Col span={9}>
            <div>
              <h3 className="font-medium text-heading-x1 mb-[8px]">
                Compromiso de transparecia salarial
              </h3>
              <Row gutter={24}>
                <Col span={12}>
                  <InputC
                    customClassName="h-[44px]"
                    placeholder="Salario mínimo"
                  />
                </Col>
                <Col span={12}>
                  <InputC
                    customClassName="h-[44px]"
                    placeholder="Salario máximo"
                  />
                </Col>
              </Row>
            </div>
          </Col>

          <Col span={9}>
            <div className="pt-[42px]">
              <Segmented
                defaultValue="Año"
                style={{ marginBottom: 30, fontWeight: "600" }}
                onChange={(value) => setAlignValue(value as Align)}
                options={["Año", "Mes", "Hora"]}
              />
              <span className="font-medium text-body-sm text-gray pl-[24px]">
                Informa del abanico salarial
              </span>
            </div>
          </Col>
        </Row>

        {/* Descripccion del trabajo */}
        <Row gutter={24} className="mb-[40px]">
          <Col span={24} className="">
            <h3 className="font-medium text-heading-x1 mb-[8px]">
              Descripción del trabajo
            </h3>

            <Input.TextArea
              rows={5}
              placeholder="Ofrece información detallada y específica sobre los requerimientos del puesto"
              className="w-full 
                border
                border-blue3 
                placeholder:text-green32 
                focus:placeholder:text-grays 
                hover:placeholder:text-black 
                hover:bg-gray3 
                hover:border-2 
                hover:border-blue3 
                hover:text-black 
                focus:border-4 
                focus:border-[#91c3fd] 
                focus:text-green32 
                rounded-[4px] 
                transition-all 
                duration-200 
                text-black
                font-medium
                text-body-md"
              style={{ height: 156 }}
            />
          </Col>
        </Row>

        {/* Información sobre el puesto */}
        <Row gutter={24} className="my-4">
          <Col span={9}>
            <div>
              <h3 className="font-medium text-heading-x1 mb-[8px]">
                Información sobre el puesto
              </h3>
              <Row gutter={24} className="">
                <Col span={12}>
                  <h3 className="font-medium text-body-md text-green32 mb-2">
                    Años de experiencia
                  </h3>
                  <Select
                    showSearch
                    placeholder="Seleccionar"
                    size="large"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      { value: "0-1", label: "0-1 años" },
                      { value: "2-3", label: "2-3 años" },
                      { value: "4-5", label: "4-5 años" },
                      { value: "5+", label: "Más de 5 años" },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <h3 className="font-medium text-body-md text-green32 mb-2">
                    Estudios mínimos
                  </h3>
                  <Select
                    showSearch
                    placeholder="Seleccionar"
                    size="large"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      { value: "bachillerato", label: "Bachillerato" },
                      { value: "diplomado", label: "Diplomado" },
                      { value: "grado", label: "Grado" },
                      { value: "master", label: "Máster" },
                    ]}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={9}>
            <div>
              <Row gutter={24} className="">
                <Col span={12}>
                  <h3 className="font-medium text-body-md text-green32 mb-2">
                    Tipo de contrato
                  </h3>
                  <Select
                    showSearch
                    placeholder="Seleccionar"
                    size="large"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      { value: "indefinido", label: "Indefinido" },
                      { value: "temporal", label: "Temporal" },
                      { value: "freelance", label: "Freelance" },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <h3 className="font-medium text-body-md text-green32 mb-2">
                    Jornada laboral
                  </h3>
                  <Select
                    showSearch
                    placeholder="Seleccionar"
                    size="large"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      { value: "completa", label: "Completa" },
                      { value: "media", label: "Media Jornada" },
                      { value: "flexible", label: "Flexible" },
                    ]}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <div className="divider"></div>

          <Col span={12}>
            <div>
              <Row gutter={24}>
                <Col span={9}>
                  <h3 className="font-medium text-body-md text-green32 mb-2">
                    Sector
                  </h3>
                  <Select
                    showSearch
                    placeholder="Seleccionar"
                    size="large"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      { value: "retail", label: "Retail" },
                      { value: "supermercado", label: "Supermercado" },
                      { value: "tecnologia", label: "Tecnología" },
                      { value: "salud", label: "Salud" },
                    ]}
                  />
                </Col>
                <Col span={11} className="pt-[40px]">
                  <span className="font-medium text-body-sm text-gray">
                    Retail x Reponedor de supermercado x
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Divider className="bg-blue3 my-[40px]"></Divider>

        {/* Palabras clave y soft skills */}
        <Row>
          <Col>
            <h3 className="font-bold text-heading-x1 mb-[40px]">
              ¿Cómo sería tu candidato ideal?
            </h3>
          </Col>
          <Col>
            <h3 className="ml-2 text-heading-x1">
              Esta información no saldra en la oferta
            </h3>
          </Col>
        </Row>

        <Row gutter={24} className="my-4">
          <Col span={15}>
            <h3 className="font-medium text-heading-x1 mb-[8px]">
              Aptitudes / Tecnología
            </h3>
            <Row gutter={24} className="mb-[20px]">
              <Col span={15}>
                <div className="w-[225px]">
                  <InputC placeholder="Pulsa intro para siguiente" />
                </div>
              </Col>

              <Col span={24}>
              <div className="flex flex-wrap mt-2">
                <CustomTag
                  text="Office"
                  onClose={() => handleClose("Office")}
                />
                <CustomTag
                  text="Atención al público"
                  onClose={() => handleClose("Atención al público")}
                />
                <CustomTag
                  text="Inglés"
                  onClose={() => handleClose("Inglés")}
                />
              </div>
              </Col>
            </Row>

            <h3 className="font-medium text-heading-x1 mb-[8px]">
              Palabras clave
            </h3>
            <Row gutter={24}>
              <Col span={15}>
                <div className="w-[225px]">
                  <InputC placeholder="Pulsa intro para siguiente" />
                </div>
              </Col>
              <Col span={24}>
                <div className="flex flex-wrap mt-2">
                  <CustomTag
                    text="Campo de Criptana"
                    onClose={() => handleClose("Campo de Criptana")}
                  />
                  <CustomTag
                    text="Alcanzar de San juan"
                    onClose={() => handleClose("Alcanzar de San juan")}
                  />
                  <CustomTag
                    text="Herencia"
                    onClose={() => handleClose("Herencia")}
                  />
                  <CustomTag
                    text="Bachillerato"
                    onClose={() => handleClose("Bachillerato")}
                  />
                  <CustomTag
                    text="Más de 2 años de experiencia"
                    onClose={() => handleClose("Más de 2 años de experiencia")}
                  />
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={9} className="mb-[144px]">
            <h3 className="font-medium text-heading-x1 mb-2">Soft skills</h3>
            <div className=" w-[476px] h-[362px]">
              <Sliders
                minLabel="Colaborativo"
                maxLabel="Autónomo"
                defaultValue={30}
              />
              <Sliders
                minLabel="Innovador"
                maxLabel="Metódico"
                defaultValue={80}
              />
              <Sliders
                minLabel="Detallista"
                maxLabel="Visionario"
                defaultValue={15}
              />
              <Sliders
                minLabel="Resiliente"
                maxLabel="Proactivo"
                defaultValue={100}
              />
              <Sliders
                minLabel="Adaptable"
                maxLabel="Consistente"
                defaultValue={80}
              />
              <h3 className="font-medium text-gray text-body-sm">
                Las 8 que decidamos
            </h3>
            </div>
          </Col>
        </Row>

        {/* Acciones */}
        <Row className="justify-end mb-[24px]"> {/* Remove the gutter property */}
          <Col>
            <Button className="bg-white text-[#5F5F5F] border border-white w-[310px] mr-[8px] principal-nav-notify-button0 ">
              Cancelar creación de oferta de empleo
            </Button>
          </Col>
          <Col>
            <Button className="text-blue3 w-[172px] border principal-nav-notify-button mr-[8px] text-body-md ">
              <EyeFilled className="text-[24px]" /> Vista previa
            </Button>
          </Col>
          <Col>
            <Button className="bg-blue3 text-white w-[122px] principal-nav-notify-button2"
                     onClick={() => navigate("/offers/killerQuestions")} >
              Siguiente
            </Button>
          </Col>
        </Row>

        <br></br>
      </div>
    </>
  );
};

export default CreateOffer;
