import React from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Button, Input, Card } from "antd";
import Header from "../../components/pages/principalNav/HeaderOffers.tsx";
import Setting from "../../components/pages/offers/SidebarOffers.tsx";
import StatCard from "../../components/pages/controlPanel/ButtonsControlP.tsx"; // Correct the import path
import Agenda from "../../components/pages/controlPanel/Calendar.tsx"; // Correct the import path
import LatestRegistrants from "../../components/pages/controlPanel/LatestRegistrations.tsx"; // Correct the import path
import OpenOffers from "../../components/pages/controlPanel/OpenOffers.tsx";
import CandidateSummary from "../../components/pages/controlPanel/CandidateSummary.tsx";
import CandidatesRegistered from "../../components/pages/controlPanel/RegisteredCandidates.tsx";
import CompletedOffers from "./SummaryActivity/CompletedOffers.tsx";
import SavedSearches from "./SummaryActivity/SavedSearches.tsx";
import LatesSearches from "./SummaryActivity/LatestSearches.tsx";

const Offers: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <Header />
      <div className="bg-white px-[24px] py-[48px]">
        <div className="flex">
          <div
            style={{ width: "234px", backgroundColor: "white" }}
            className="p-0"
          >
            <Setting />
          </div>
          <div className="flex-1 pl-[24px]">
            {/* Stat Cards */}
            <Row gutter={[16, 16]} className="mb-8">
              <Col xl={6} xs={24} sm={12} md={6}>
                <StatCard
                  title="Reponedor de supermercado"
                  value={234}
                  description="inscritos hoy"
                />
              </Col>
              <Col xl={6} xs={24} sm={12} md={6}>
                <StatCard
                  title="Nuevos candidatos"
                  value={98}
                  description="últimas 24h"
                />
              </Col>
              <Col xl={6} xs={24} sm={12} md={6}>
                <StatCard
                  title="Reponedor de supermercado"
                  value={234}
                  description="inscritos hoy"
                />
              </Col>
              <Col xl={6} xs={24} sm={12} md={6}>
                <StatCard
                  title="Pricing"
                  value={13}
                  description="CV por descubrir"
                />
              </Col>
            </Row>

            {/* Main Dashboard */}
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24}>
                <Card className="border border-sky-blue0 rounded-[6px]">
                  <Row gutter={[16, 16]} className="pb-[26px]">
                    <Col xs={24} sm={12} md={12}>
                      <div className="border border-sky-200 rounded-md w-[462] h-[561px]">
                        <h3 className="font-bold text-heading-x1 pb-[22px]">
                          Agenda
                        </h3>
                        <Agenda />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <div className="w-[377] h-[195px] mb-[80px]">
                        <CandidateSummary />
                      </div>
                      <div className="w-[439] h-[285px]">
                        <CandidatesRegistered />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={12}>
                      <div className="border border-sky-200 px-4 py-6 rounded-md w-[463] h-[306px]">
                        <OpenOffers />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <div className="border border-sky-200 px-4 py-6 rounded-md w-[463] h-[306px]">
                        <LatestRegistrants />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24}>
                      <h3 className="font-bold text-heading-x1 py-[26px]">
                        Resumen de tu actividad
                      </h3>
                      <div className="tabs-merere">
                        <div className="tab-links-merere flex justify-center px-4">
                          <button
                            className={`tab-link-merere ${
                              location.pathname ===
                              "/controlPanel/completeOffers"
                                ? "active-merere"
                                : ""
                            }`}
                            onClick={() =>
                              navigate("/controlPanel/completeOffers")
                            }
                          >
                            <span className="inline">Últimas Búsquedas</span>
                          </button>
                          <button
                            className={`tab-link-merere ${
                              location.pathname === "/controlPanel/savedSearches"
                                ? "active-merere"
                                : ""
                            }`}
                            onClick={() => navigate("/controlPanel/savedSearches")}
                          >
                            <span className="inline">Búsquedas guardadas</span>
                          </button>
                          <button
                            className={`tab-link-merere ${
                              location.pathname === "/controlPanel/latesSearches"
                                ? "active-merere"
                                : ""
                            }`}
                            onClick={() =>
                              navigate("/controlPanel/latesSearches")
                            }
                          >
                            <span className="inline">
                            Ofertas finalizadas
                            </span>
                          </button>
                        </div>

                        {/* Contenido de las tabs */}
                        <div className="tab-content-merere p-4">
                          <Routes>
                            <Route
                              path="completeOffers"
                              element={<CompletedOffers />}
                            />
                            <Route
                              path="savedSearches"
                              element={<SavedSearches />}
                            />
                            <Route
                              path="latesSearches"
                              element={<LatesSearches />}
                            />
                          </Routes>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offers;
