import React, { useState, useEffect } from 'react';
import { Row, Col, Skeleton } from 'antd';
import CardEmpleo from '../../../components/cards/CardEmployment.tsx';
import Information from '../../../components/pages/employment/recommendations/Information.tsx';
import AlertFilter from '../../../components/pages/employment/recommendations/AlertFilter.tsx';
import InfiniteScroll from 'react-infinite-scroll-component';

const CardEmpleoExample: React.FC = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const [appliedCards, setAppliedCards] = useState<{ [key: number]: boolean }>({});
  const [loadingCards, setLoadingCards] = useState<{ [key: number]: boolean }>({ 0: true, 1: true, 2: true });
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
  const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null);
  const [cardsData, setCardsData] = useState([
    {
      exampleProps: {
        companyName: "Supermercados Carrefour",
        jobTitle: "Reponedor de supermercado",
        location: "Campo de Criptana",
        salary: "18.000€",
        schedule: "Parcial",
        ratings: "20 valoraciones",
        activeOffers: "90 ofertas activas",
        followers: "450 Seguidores",
        description: "Importante cadena de alimentación busca incorporar un/a dependiente/a reponedor/a en Fuerteventura...",
        postedTime: "Subida hace 23h",
        applied: false
      },
      informationProps: {
        title: "Reponedor de supermercado",
        company: "Supermercados Carrefour",
        location: "Campo de Criptana",
        employmentType: "Parcial",
        salary: "18.000€",
        comments: 20,
        applicantsCount: "Inscritos",
        introText: "En Carrefour consideramos que todas las personas tienen un talento que ofrecer. Buscamos personas que empaticen con los clientes, compartan nuestros valores y estén dispuestas a probar nuevos retos. Si desbordas energía, te gusta que todo esté en su sitio y te encanta trabajar codo con codo con tus compañeros, trae tu talento a LOGÍSTICA.",
        requirements: [
          "Inglés: Nivel B1",
          "Incorporación: inmediata",
          "Jornada: Tiempo parcial, mañana",
          "Qué harás día a día"
        ],
        responsibilities: [
          "Cargar y descargar las mercancías de los camiones, comprobar su correcto estado, y chequear los correspondientes albaranes para evitar posibles errores.",
          "Poner la mercancía a disposición del cliente en su correspondiente punto de venta en la tienda, así como realizar las devoluciones de mercancía al almacén en caso de que no sea necesaria en el punto de venta, con el objetivo de garantizar el stock de mercancía en el punto de ventas.",
          "Estás disponible para ayudar a los compañeros del departamento y otras áreas de la tienda cuando sea necesario.",
          "Te comprometes con todas las reglas y requisitos de Carrefour en materia de salud, seguridad y protección, y ayudas activamente a los compañeros a evitar cualquier incidente en el que puedan verse implicados colaboradores, clientes, equipos o instalaciones."
        ],
        extraText: [
          "Porque formas parte de la gran familia CARREFOUR queremos compartir contigo los momentos importantes, por ello celebramos aniversarios, fiestas, Teams Buildings, la llegada del verano, regalo de Navidad. ¡Siempre hay un motivo para sonreír!"
        ],
        extraText2: [
          "¡Muchos más te están esperando, ¡descúbrelos!"
        ],
        postedTime: "Subida hace 23h"
      }
    },
    // ... otros objetos de datos de tarjetas ...
  ]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingCards({ 0: false, 1: false, 2: false });
    }, 2000); // Simula 2 segundos de carga

    return () => clearTimeout(timer);
  }, []);

  const fetchMoreData = () => {
    if (cardsData.length >= 6) {
      setHasMore(false);
      return;
    }
    // Simular la carga de más datos
    setTimeout(() => {
      setCardsData([
        ...cardsData,
        {
          exampleProps: {
            companyName: "Nueva Empresa",
            jobTitle: "Nuevo Empleo",
            location: "Nueva Ubicación",
            salary: "40.000€",
            schedule: "Completo",
            ratings: "10 valoraciones",
            activeOffers: "5 ofertas activas",
            followers: "300 Seguidores",
            description: "Nueva descripción de trabajo...",
            postedTime: "Subida hace 1 día",
            applied: false
          },
          informationProps: {
            title: "Nuevo Empleo",
            company: "Nueva Empresa",
            location: "Nueva Ubicación",
            employmentType: "Completo",
            salary: "40.000€",
            comments: 10,
            applicantsCount: "Inscritos",
            introText: "Nueva introducción...",
            requirements: [
              "Nuevo requisito 1",
              "Nuevo requisito 2"
            ],
            responsibilities: [
              "Nueva responsabilidad 1",
              "Nueva responsabilidad 2"
            ],
            extraText: [
              "Nuevo texto extra"
            ],
            extraText2: [
              "¡Descúbrelo!"
            ],
            postedTime: "Subida hace 1 día"
          }
        },
        //... Más datos
      ]);
    }, 1500);
  };

  const handleCardClick = (index: number) => {
    setLoadingInfo(true);
    setSelectedCardIndex(index);

    setTimeout(() => {
      setLoadingInfo(false);
    }, 2000); // Simula 2 segundos de carga
  };

  const handleApply = (index: number | null) => {
    if (index !== null) {
      setAppliedCards((prev) => ({
        ...prev,
        [index]: !prev[index]
      }));
    }
  };

  const handleFilterClick = () => {
    alert('Filtro aplicado');
  };

  const handleQuickApplyClick = () => {
    alert('Solicitud rápida aplicada');
  };

  const handleRatingsClick = (value: string) => {
    alert(`Valoraciones: ${value} aplicadas`);
  };

  const handleProvinceClick = (value: string) => {
    alert(`Provincia: ${value} seleccionada`);
  };

  const handleCreateAlertClick = () => {
    alert('Nueva alerta creada');
  };

  return (
    <div style={{ padding: '10px' }}>
      <AlertFilter
        newOffersCount={68}
        onFilterClick={handleFilterClick}
        onQuickApplyClick={handleQuickApplyClick}
        onRatingsClick={handleRatingsClick}
        onProvinceClick={handleProvinceClick}
        onCreateAlertClick={handleCreateAlertClick}
        newOffersText="Nuevas ofertas que se adaptan a ti"
        createAlertText="Crear nueva alerta"
        filterButtonText="Todos los filtros"
        quickApplyButtonText="Solicitudes rápidas"
        ratingsDefaultText="Valoraciones"
        provinceDefaultText="Provincia"
        ratingsOptions={[
          { value: '5-stars', label: '5 Estrellas' },
          { value: '4-stars', label: '4 Estrellas' },
          { value: '3-stars', label: '3 Estrellas' },
        ]}
        provinceOptions={[
          { value: 'madrid', label: 'Madrid' },
          { value: 'barcelona', label: 'Barcelona' },
          { value: 'valencia', label: 'Valencia' },
        ]}
      />
      <Row gutter={[16, 16]} style={{ margin: 0 }}>
  <Col xs={24} md={10} style={{ padding: 0, height: '100%' }}>
    <InfiniteScroll
      style={{ width: '100%',paddingTop: '2px', paddingLeft:'2px', paddingRight:'8px', boxSizing: 'border-box' }} 
      dataLength={cardsData.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={
        <div style={{ marginBottom: '16px' }}>
          <Skeleton
            active
            avatar={{ shape: 'square', size: 'large' }}
            paragraph={{ rows: 3 }}
          />
        </div>
      }
      endMessage={<p>No hay más ofertas para mostrar</p>}
    >
      {cardsData.map((card, index) => (
        <div
          key={index}
          className={`card-container ${hoveredCardIndex === index ? 'hovered' : ''} ${selectedCardIndex === index ? 'selected' : ''}`}
          onClick={() => handleCardClick(index)}
          onMouseEnter={() => setHoveredCardIndex(index)}
          onMouseLeave={() => setHoveredCardIndex(null)}
          style={{
            marginBottom: '16px', // Space between cards
            transition: 'box-shadow 0.3s ease-in-out',
            boxShadow: selectedCardIndex === index
              ? '0 3px 5px rgba(0, 0, 0, 0.3)'
              : hoveredCardIndex === index
              ? '0 3px 5px rgba(0, 0, 0, 0.2)'
              : '0 3px 3px rgba(0, 0, 0, 0.1)',
            borderRadius: '6px'
          }}
        >
          <CardEmpleo
            {...card.exampleProps}
            applied={!!appliedCards[index]}
            loading={loadingCards[index]}
          />
        </div>
      ))}
    </InfiniteScroll>
  </Col>
  <Col xs={24} md={14} style={{ paddingLeft: '8px', paddingRight:'0px' }}>
    {selectedCardIndex !== null && (
      <Information
        {...cardsData[selectedCardIndex].informationProps}
        applied={!!appliedCards[selectedCardIndex]}
        onApply={() => handleApply(selectedCardIndex)}
        loading={loadingInfo}
      />
    )}
  </Col>
</Row>

</div>
);
};
export default CardEmpleoExample;