// Profile.tsx

import React from 'react';
import { Card } from 'antd';
import ProfileInfo from './ProfileInfo.tsx';
import ReviewSection from './ReviewSection.tsx';
import Portfolio from './Portfolio.tsx';
import ViewerList from './ViewerList.tsx';

interface ProfileProps {
  profileInfo: {
    name: string;
    lastName: string;
    aboutMe: string;
    skills: string[];
    experience: {
      title: string;
      company: string;
      other: string;
      location: string;
      startDate: string;
      endDate: string;
      totalDate: string;
      description: string;
      ratings: string;
      tags: string;
    }[];
    education: {
      degree: string;
      school: string;
      others: string;
      location: string;
      startDate: string;
      endDate: string;
      totalDates: string;
      description: string;
      rating: string;
      tag: string;
    }[];
    languages: { name: string; level: string }[];
  };
  reviews: {
    name: string;
    jobTitle: string;
    rating: number;
    pros: string;
    cons: string;
    review: string;
    avatarUrl: string;
    timeAgo: string;
  }[];
  portfolio: {
    name: string;
    description: string;
    skills: string;
    link: string;
  }[];
  viewers: { name: string; timeAgo: string }[];
}

const Profile: React.FC<ProfileProps> = ({
  profileInfo,
  reviews,
  portfolio,
  viewers,
}) => (
  <Card className='rounded-lg border border-sky-blue0'>
    {/* Adding IDs for each section */}
    <div id="profile-info-section">
      <ProfileInfo {...profileInfo} />
    </div>
    <div id="review-section">
      <ReviewSection reviews={reviews} />
    </div>
    <div id="portfolio-section">
      <Portfolio projects={portfolio} />
    </div>
    <div id="viewer-list-section">
      <ViewerList viewers={viewers} />
    </div>
  </Card>
);

export default Profile;
