import React, { useState } from "react";
import { Row, Col, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { EyeFilled } from "@ant-design/icons";
import IconRecommendations from "../../../../assets/icons/recommendations.svg";
import "../../../../components/styles/pages/principalNav/PrincipalNav.css";
import InputC from "./ComponentsKillersQ/Input.tsx";
import KillerQuestionItem from "../../../../components/pages/offers/KillerQuestions/KillerQuestionItem.tsx";
import QuestionModal from '../../../../components/pages/offers/KillerQuestions/ModalOffers.tsx';

const { Text } = Typography;

const KillerQuestions: React.FC = () => {
  const navigate = useNavigate();

  // Estado para controlar la visibilidad del modal
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Función para mostrar el modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="bg-white pr-6">
      {/* Header */}
      <Row justify="space-between" align="middle" className="mb-6">
        <Col>
          <h1 className="font-extralight text-5xl text-blue3">
            Killer Questions
          </h1>
        </Col>
        <Col className="mt-[13px]">
          <Button className="text-blue3 pl-[30px] border principal-nav-notify-button font-semibold mr-[8px] text-body-sm w-[227px] h-[35px]">
            Formular preguntas con IA
            <img src={IconRecommendations} className="icon-merere w-[30px]" alt="Icon" />
          </Button>
          {/* Botón para cargar preguntas, llama a la función showModal */}
          <Button
            className="text-blue3 border principal-nav-notify-button font-semibold text-body-sm w-[227px] h-[35px]"
            onClick={showModal} // Abre el modal al hacer clic
          >
            Cargar preguntas de otra oferta
          </Button>
          {/* Componente del modal */}
          <QuestionModal
            visible={isModalVisible} // Estado de visibilidad del modal
            onClose={closeModal} // Función para cerrar el modal
          />
        </Col>
      </Row>

      {/* Offer Name Input */}
      <Row gutter={24} className="mb-[64px]">
        <Col span={15}>
          <Text className="font-bold text-body-md">Nombre de la oferta</Text>
          <InputC placeholder="Nombre" className="w-[620px]" />
          <Text className="text-caption text-green32">
            Escribe un nombre descriptivo, para que te ayude a encontrarla
            después
          </Text>
        </Col>
      </Row>

      {/* Pregunta 1 */}
      <div className="mb-[56px]">
      <KillerQuestionItem
        number={1}
        title="¿Cuántos años de experiencia tienes trabajando como cajero?"
        placeholder="¿Cuántos años de experiencia tienes trabajando como cajero?"
        defaultSelectedOption="Margen numérico" // Opción por defecto
      />
      </div>
      <div className="mb-[56px]">
      <KillerQuestionItem
        number={2}
        title="¿En qué tipo de supermercado o tienda de tamaño tienes más experiencia: grande, mediano o pequeño?"
        placeholder="¿En qué tipo de supermercado o tienda de tamaño tienes más experiencia: grande, mediano o pequeño?"
        defaultSelectedOption="Escala lineal" // Opción por defecto
      />
      </div>
      <div className="mb-[56px]">

      <KillerQuestionItem
        number={3}
        title="¿Tienes experiencia en el manejo de múltiples formas de pago, como efectivo, tarjetas de crédito y cheques?"
        placeholder="¿Tienes experiencia en el manejo de múltiples formas de pago, como efectivo, tarjetas de crédito y cheques?"
        defaultSelectedOption="Elegir opción" // Opción por defecto
      />
      </div>
      <div className="mb-[56px]">
      <KillerQuestionItem
        number={4}
        title="Cuéntame sobre una situación donde tuviste que manejar a un cliente difícil. ¿Cómo resolviste la situación?"
        placeholder="Cuéntame sobre una situación donde tuviste que manejar a un cliente difícil. ¿Cómo resolviste la situación?"
        defaultSelectedOption="Respuesta personalizada" // Opción por defecto
      />
      </div>
      <div className="mb-[56px]">
      <KillerQuestionItem
        number={5}
        title="Como cajero, ¿qué actividad prefieres realizar?"
        placeholder="Como cajero, ¿qué actividad prefieres realizar?"
        defaultSelectedOption="Diferentes casillas" // Opción por defecto
      />
      </div>

      {/* Acciones */}
      <Row className="justify-end mb-[24px]">
        <Col>
          <Button className="bg-white text-[#5F5F5F] border border-white w-[310px] mr-[8px] principal-nav-notify-button0">
            Cancelar creación de oferta de empleo
          </Button>
        </Col>
        <Col>
          <Button
            className="text-blue3 w-[98px] border principal-nav-notify-button mr-[8px] text-body-md"
            onClick={() => navigate("/offers/createOffer")}
          >
            Volver
          </Button>
        </Col>
        <Col>
          <Button className="bg-blue3 text-white w-[251px] principal-nav-notify-button2">
            <EyeFilled className="text-[24px]" /> Vista previa y finalizar
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default KillerQuestions;
