import React from 'react';
import { Button } from 'antd';
import IconCheck from '../../../assets/img/company/CheckW.svg'; // Importa el icono de check
import IconAlerts from '../../../assets/icons/alerts.svg'; // Importa el icono de alertas
import LogoCompany from '../../../assets/img/company/logo_example.png';

interface CompanyInfoProps {
  title: string;
  location: string;
  industry: string;
  workers: string;
  avatarUrl?: string; // Prop para la imagen del avatar
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({ title, location, workers, avatarUrl }) => {
  return (
    <div className="flex justify-between items-center bg-white p-4 shadow-none">
      <div className="flex items-center">
        <div
          className={`w-24 h-24 ${avatarUrl ? '' : 'bg-white'} border-black border rounded mr-4`}
          style={{ backgroundImage: avatarUrl ? `url(${LogoCompany})` : `url(${LogoCompany})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        ></div>
        <div>
          <h1 className="font-semibold text-2xl" style={{ fontSize: '30px' }}>{title}</h1>
          <div className="flex space-x-2 mt-2">
            <span className="bg-white text-gray-800 border border-gray2 py-1 px-2 rounded-full text-xs">Retail</span>
            <span className="bg-white text-gray-800 border border-gray2 py-1 px-2 rounded-full text-xs">{location}</span>
            <span className="bg-white text-gray-800 border border-gray2 py-1 px-2 rounded-full text-xs">{workers}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <Button
          type="primary"
          icon={<img src={IconCheck} alt="Check Icon" className="h-5 w-5" />}
          className="rounded-md h-9 bg-blue3 text-white border-blue-500 hover:bg-blue-600 font-semibold"
        >
          Valorar empresa
        </Button>
        <Button
          type="default"
          icon={<img src={IconAlerts} alt="Alerts Icon" className="h-5 w-5" />}
          className="rounded-md h-9 bg-white border-blue3 text-blue3 font-bold hover:text-blue-600"
        >
          Seguir a esta empresa
        </Button>
      </div>
    </div>
  );
};

export default CompanyInfo;
