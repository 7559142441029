import React, { useState } from "react";
import { Layout, Checkbox, Input, Button, Radio } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import Rating from "../../../rating/Rating.tsx";
import CheckboxComponent from "../../../checkbox/Checkbox.tsx";
import ButtonText from "../../../button/ButtonText.tsx";
import ButtonCom from "../../../button/Button.tsx";


const { Sider } = Layout;

const ratings = [5, 4, 3, 2, 1, 0];

const buttonsText1 = [
  {
    type: "link",
    label: "Office",
    size: "small",
    textColor: "#006497",
    color: "white",
    underline: true,
    fontWeight: "bold", // Añadir fontWeight
    fontSize: "14px",   // Ajustar el tamaño de la fuente
  },
  {
    type: "link",
    label: "Inglés",
    size: "small",
    textColor: "#006497",
    color: "white",
    underline: true,
    fontWeight: "bold", // Añadir fontWeight
    fontSize: "14px",   // Ajustar el tamaño de la fuente
  },
  {
    type: "link",
    label: "Barcelona",
    size: "small",
    textColor: "#006497",
    color: "white",
    underline: true,
    fontWeight: "bold", // Añadir fontWeight
    fontSize: "14px",   // Ajustar el tamaño de la fuente
  },
  {
    type: "link",
    label: "Madrid",
    size: "small",
    textColor: "#006497",
    color: "white",
    underline: true,
    fontWeight: "bold", // Añadir fontWeight
    fontSize: "14px",   // Ajustar el tamaño de la fuente
  },
  {
    type: "link",
    label: "Administrativo",
    size: "small",
    textColor: "#006497",
    color: "white",
    underline: true,
    fontWeight: "bold", // Añadir fontWeight
    fontSize: "14px",   // Ajustar el tamaño de la fuente
  },
];

// Utilizamos todos los botones de buttons1
const keywordButtonsText = buttonsText1;

const buttons1 = [
  { type: 'link', label: 'Añadir', border: '1px solid #006497', color: 'transparent', size: 'small', textColor: '#006497', fontSize: '14px', fontWeight: 'bold' },
];
const Setting: React.FC = () => {
  const [openSections, setOpenSections] = useState({
    valoraciones: false,
    palabrasClave: false,
    reclutadores: false,
    sector: false,
  });

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const Sectores = [
    "Educación",
    "Eventos y entretenimiento",
    "Limpieza",
    "Retail",
    "Salud y bienestar",
    "Servicios a domicilio",
    "Turismo",
  ];
  const Tamaño = [
    "1 persona",
    "De 2 a 10",
    "De 11 a 50",
    "De 51 a 100",
    "De  101 a 500",
    "Más de 500",
  ];

  const handleAddClick = () => {
    console.log("Botón Añadir clickeado");
    // Aquí puedes añadir cualquier lógica que desees ejecutar al hacer clic en el botón
  };

  return (
    <div className="">
      <Sider
        width={234}
        style={{ backgroundColor: "white" }}
        className="rounded-lg shadow-md p-5 border border-sky-blue0 shadow-gray-400"
      >
        <h2 className="text-heading-md font-bold text-green42 mb-1">Filtros</h2>
        <h2 className="text-body-md font-bold text-green42">
          Refina tu búsqueda
        </h2>
        <h2 className="text-caption font-bold text-blue3 mb-6">
          Eliminar todos los filtros
        </h2>

        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("valoraciones")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Valoraciones
            </h3>
            {openSections.valoraciones ? (
              <CaretUpOutlined />
            ) : (
              <CaretDownOutlined />
            )}
          </div>
          {openSections.valoraciones && (
            <div className="flex flex-col">
              {ratings.map((value) => (
                <Checkbox key={value} value={value}>
                  <Rating value={value} size={20} />
                </Checkbox>
              ))}
            </div>
          )}
        </div>

        <div className="mb-4">
          {/* Título y campo para añadir palabras clave */}
          <div className="flex justify-between items-center cursor-pointer">
            <h3 className="font-bold text-heading-x1 mb-2 text-green42">Palabras clave</h3>
          </div>

          {/* Input para añadir nuevas palabras */}
          <div className="flex ">
            <Input placeholder="Escribe aquí..." className="h-[36px]" />
            {/* Uso del botón importado */}
            <div className="w-[70px]">
              <ButtonCom buttons={[{ ...buttons1[0], label: 'Añadir', onClick: handleAddClick, textAlign: 'center' }]} />
            </div>
          </div>

          {/* Contenedor de botones estilo texto */}
          <div className="flex flex-wrap text-blue-600">
            {keywordButtonsText.map((button, index) => (
              <span key={index} className="flex text-blue3 font-bold text-body-sm">
                <ButtonText buttons={[button]} />
                {index < keywordButtonsText.length - 1 && <span>,&nbsp;</span>}
              </span>
            ))}
          </div>
          <Checkbox>
            <h1 className="font-bold text-heading-x1 mb-2 pt-8 text-green42">Solo reclutadores independientes</h1>
          </Checkbox>
        </div>


        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("sector")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Sector
            </h3>
            {openSections.sector ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </div>
          {openSections.sector && (
            <div
              className="flex flex-col mb-2"
            >
              {Sectores.map((sector) => (
                <Checkbox key={sector} className="font-bold text-body-md mb-2 text-green42">
                  {sector}
                </Checkbox>
              ))}
            </div>
          )}
        </div>

        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("reclutadores")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Tamaño de la empresa
            </h3>
            {openSections.reclutadores ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </div>
          {openSections.reclutadores && (
            <div
              className="flex flex-col mb-2"
            >
              {Tamaño.map((reclutadores) => (
                <Checkbox key={reclutadores} className="font-bold text-body-md mb-2 text-green42">
                  {reclutadores}
                </Checkbox>
              ))}
            </div>
          )}
        </div>

        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("sector")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Búsqueda sugeridas
            </h3>
            {openSections.sector ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </div>
          {openSections.sector && (
            <div className="flex flex-col text-bluec">
              {" "}
              {/* Cambia el color del texto a azul */}
              {[
                "Dedicadas a la logística",
                "Mejor valoradas de Ciudad Real",
                "Reclutadores independientes que operan en Ciudad Real",
                "Especializadas en retail",
              ].map((sector) => (
                <div key={sector} className="mb-2 font-bold text-body-md">
                  {sector}
                </div> // Usamos un div para cada elemento en lugar de un checkbox
              ))}
            </div>
          )}
        </div>
      </Sider>
    </div>
  );
};

export default Setting;
