import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Grid } from 'antd';
import IconOfertas from '../../../assets/icons/ofertas.svg';
import IconSeguidores from '../../../assets/icons/seguidores.svg';
import IconTrabajadores from '../../../assets/icons/trabajadores.svg';
import IconAdm from '../../../assets/icons/adm.svg';
import CompanyHeader from '../../../components/pages/company/CompanyHeader.tsx';
import CompanyInfo from '../../../components/pages/company/CompanyInfo.tsx';
import CompanyTabs from '../../../components/pages/company/CompanyTabs.tsx';
import CompanyRatings from '../../../components/pages/company/CompanyRatings.tsx';
import Header from '../../../components/pages/principalNav/PrincipalNav.tsx';
import CompanySidebar from '../../../components/pages/company/CompanySidebar.tsx';
import ReviewCard from '../../../components/pages/myPortal/CardProfile.tsx';
import { motion } from "framer-motion";
import IconRight from '../../../assets/icons/arrow_right.svg';
import IconLeft from '../../../assets/icons/arrow_left.svg';
import '../../../styles/pages/company/companyDetail/CompanyDetail.css'

const { useBreakpoint } = Grid;

interface Review {
  name: string;
  jobTitle: string;
  rating: number;
  pros: string;
  cons: string;
  review: string;
  avatarUrl: string;
  timeAgo: string;
}

interface CompanyData {
  title: string;
  description: string;
  location: string;
  avatarUrl: string;
  reviews: number;
  activeOffers: number;
  followers: number;
  workers: number;
  website: string;
  industry: string;
  companyDescription: string;
  reviewCards: Review[];
}

const CompanyDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [rotate, setRotate] = useState(0);

  const screens = useBreakpoint();


  useEffect(() => {
    const fetchCompanyData = () => {
      const mockData: { [key: string]: CompanyData } = {
        '1': {
          title: "Supermercados Carrefour",
          description: "Retail • Alimentación y bebidas",
          location: "Madrid, Spain",
          avatarUrl: "https://popgroup.global/wp-content/uploads/2017/11/carrefour-logo-1.png",
          reviews: 120,
          activeOffers: 90,
          followers: 450,
          workers: 500,
          website: "carrefour.com",
          industry: "Retail • Alimentación y bebidas",
          companyDescription: "Carrefour es una multinacional francesa, uno de los mayores grupos de distribución a nivel mundial. Fundada en 1958, la empresa ha crecido para convertirse en una de las cadenas de supermercados y hipermercados líderes, con presencia en más de 30 países...",
          reviewCards: [
            {
              name: "1 Juan Pérez",
              jobTitle: "Gerente",
              rating: 4,
              pros: "Buen ambiente laboral",
              cons: "Salarios bajos",
              review: "El ambiente de trabajo es excelente, pero los salarios pueden mejorar.",
              avatarUrl: "https://example.com/avatar1.jpg",
              timeAgo: "Hace 2 días"
            },
            {
              name: "2 María López",
              jobTitle: "Cajera",
              rating: 3.5,
              pros: "Horarios flexibles",
              cons: "Mucha presión",
              review: "Los horarios flexibles son geniales, pero el trabajo puede ser muy estresante.",
              avatarUrl: "https://example.com/avatar2.jpg",
              timeAgo: "Hace 3 días"
            },
            {
              name: "3 Juan Pérez",
              jobTitle: "Gerente",
              rating: 4,
              pros: "Buen ambiente laboral",
              cons: "Salarios bajos",
              review: "El ambiente de trabajo es excelente, pero los salarios pueden mejorar.",
              avatarUrl: "https://example.com/avatar1.jpg",
              timeAgo: "Hace 2 días"
            },
            {
              name: "4 María López",
              jobTitle: "Cajera",
              rating: 3.5,
              pros: "Horarios flexibles",
              cons: "Mucha presión",
              review: "Los horarios flexibles son geniales, pero el trabajo puede ser muy estresante.",
              avatarUrl: "https://example.com/avatar2.jpg",
              timeAgo: "Hace 3 días"
            },
            {
              name: "5 Juan Pérez",
              jobTitle: "Gerente",
              rating: 4,
              pros: "Buen ambiente laboral",
              cons: "Salarios bajos",
              review: "El ambiente de trabajo es excelente, pero los salarios pueden mejorar.",
              avatarUrl: "https://example.com/avatar1.jpg",
              timeAgo: "Hace 2 días"
            },
            {
              name: "6 María López",
              jobTitle: "Cajera",
              rating: 3.5,
              pros: "Horarios flexibles",
              cons: "Mucha presión",
              review: "Los horarios flexibles son geniales, pero el trabajo puede ser muy estresante.",
              avatarUrl: "https://example.com/avatar2.jpg",
              timeAgo: "Hace 3 días"
            },
            {
              name: "7 Juan Pérez",
              jobTitle: "Gerente",
              rating: 4,
              pros: "Buen ambiente laboral",
              cons: "Salarios bajos",
              review: "El ambiente de trabajo es excelente, pero los salarios pueden mejorar.",
              avatarUrl: "https://example.com/avatar1.jpg",
              timeAgo: "Hace 2 días"
            },
            {
              name: "8 María López",
              jobTitle: "Cajera",
              rating: 3.5,
              pros: "Horarios flexibles",
              cons: "Mucha presión",
              review: "Los horarios flexibles son geniales, pero el trabajo puede ser muy estresante.",
              avatarUrl: "https://example.com/avatar2.jpg",
              timeAgo: "Hace 3 días"
            },
            {
              name: "9 Juan Pérez",
              jobTitle: "Gerente",
              rating: 4,
              pros: "Buen ambiente laboral",
              cons: "Salarios bajos",
              review: "El ambiente de trabajo es excelente, pero los salarios pueden mejorar.",
              avatarUrl: "https://example.com/avatar1.jpg",
              timeAgo: "Hace 2 días"
            },
            {
              name: "10 María López",
              jobTitle: "Cajera",
              rating: 3.5,
              pros: "Horarios flexibles",
              cons: "Mucha presión",
              review: "Los horarios flexibles son geniales, pero el trabajo puede ser muy estresante.",
              avatarUrl: "https://example.com/avatar2.jpg",
              timeAgo: "Hace 3 días"
            },
            {
              name: "11 Juan Pérez",
              jobTitle: "Gerente",
              rating: 4,
              pros: "Buen ambiente laboral",
              cons: "Salarios bajos",
              review: "El ambiente de trabajo es excelente, pero los salarios pueden mejorar.",
              avatarUrl: "https://example.com/avatar1.jpg",
              timeAgo: "Hace 2 días"
            },
            {
              name: "12 María López",
              jobTitle: "Cajera",
              rating: 3.5,
              pros: "Horarios flexibles",
              cons: "Mucha presión",
              review: "Los horarios flexibles son geniales, pero el trabajo puede ser muy estresante.",
              avatarUrl: "https://example.com/avatar2.jpg",
              timeAgo: "Hace 3 días"
            },
          ]
        },
        '2': {
          title: "Mercadona",
          description: "Retail • Alimentación y bebidas",
          location: "Valencia, Spain",
          avatarUrl: "https://popgroup.global/wp-content/uploads/2017/11/carrefour-logo-1.png",
          reviews: 20,
          activeOffers: 90,
          followers: 450,
          workers: 500,
          website: "mercadona.es",
          industry: "Retail • Alimentación y bebidas",
          companyDescription: "Mercadona es una de las principales cadenas de supermercados en España, conocida por su compromiso con la calidad y el servicio al cliente. Con más de 1600 tiendas en todo el país, Mercadona ha establecido un modelo de negocio basado en la eficiencia y la satisfacción del cliente...",
          reviewCards: [
            {
              name: "Carlos Sánchez",
              jobTitle: "Reponedor",
              rating: 4.5,
              pros: "Buenas oportunidades de crecimiento",
              cons: "Turnos largos",
              review: "Hay muchas oportunidades de crecimiento, aunque los turnos pueden ser largos.",
              avatarUrl: "https://example.com/avatar3.jpg",
              timeAgo: "Hace 1 semana"
            }
          ]
        },
        // Otros datos simulados...
      };

      setCompanyData(mockData[id]);
    };

    fetchCompanyData();
  }, [id]);

  if (!companyData) return <div>Loading...</div>;

  const companyTabsData = [
    { icon: IconOfertas, label: 'Ofertas activas', value: `${companyData.activeOffers}` },
    { icon: IconSeguidores, label: 'Seguidores', value: `${companyData.followers}` },
    { icon: IconTrabajadores, label: 'Trabajadores', value: `+${companyData.workers}` },
    { icon: IconAdm, label: '', value: 'Administrativo | Reponedor | Ventas' },
  ];

  return (
    <div className="overflow-x-hidden">
      <Header />
      <CompanyHeader />
      <div className="company-detail-container ml-6 mr-6">
        <Row gutter={[16, 16]} className='mb-8'>
          <Col xxl={20} xl={19} lg={18} md={16} sm={24} xs={24} className="px-6">
            <CompanyInfo
              title={companyData.title}
              location={companyData.location}
              industry={companyData.description}
              workers={`+${companyData.workers} trabajadores`}
            />
            <div className="mb-8">
              <CompanyTabs tabs={companyTabsData} />
            </div>
            <Row gutter={[16, 16]} className="mt-8">
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <div>
                  <h4 className="font-bold text-base mb-1 ml-2">Página web</h4>
                  <p className="text-base text-blue3 mb-1 ml-2">
                    <a href={`https://${companyData.website}`} target="_blank" rel="noopener noreferrer">
                      {companyData.website}
                    </a>
                  </p>
                  <h4 className="font-bold text-base mb-1 ml-2">Sector al que se dedica</h4>
                  <p className="text-base text-blue3 mb-1 ml-2">{companyData.industry}</p>
                  <h4 className="font-bold text-base mb-1 ml-2">Sede fiscal</h4>
                  <p className="text-base text-blue3 mb-1 ml-2">{companyData.location}</p>
                  <h4 className="font-bold text-base mb-1 ml-2">Tamaño de empresa</h4>
                  <p className="text-base text-gray-500 mb-1 ml-2">+{companyData.workers} trabajadores</p>
                </div>
              </Col>
              <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                <CompanyRatings
                  totalRatings={companyData.reviews}
                  rating={4.5}
                  labels={['Ambiente de trabajo', 'Salarios', 'Oportunidades de crecimiento']}
                  recommendPercentage={85}
                  recruiterScore={90}
                />
              </Col>
            </Row>

            <div className="border-t border-gray2 my-4"></div>

            {/* Descripción de la empresa */}
            <h4 className="font-bold text-lg mb-4 ml-2">Descripción de la empresa</h4>
            <p className="text-base text-gray mb-10 ml-2">
              {companyData.companyDescription} <span className="text-blue3 font-bold cursor-pointer">Leer más</span>
            </p>

            {/* Valoraciones (Review Cards) */}
            <div className="border border-blue3 rounded-lg mt-4 pt-[29px] pl-[62px] pb-[29px] relative">

              <div style={{
                position: 'absolute', right: "0px", top: '0px', zIndex: '1', background: 'white', height: '100%',
                alignContent: 'center', paddingRight: '30px', opacity: '0.8'
              }}>
                <img src={IconRight} />
              </div>
              <div
                onClick={() => {
                  if (screens.xxl) {
                    if (x > -2680) {
                      setX((prev) => (prev - 335));
                    }
                  } else if (screens.xl && x > -3350) {
                    setX((prev) => (prev - 335));
                  } else if (screens.md && x > -3350) {
                    setX((prev) => (prev - 335));
                  } else {
                    // setVisibleItems(2);
                  }
                }}
                style={{
                  position: 'absolute', right: "0px", top: '0px', zIndex: '1', height: '100%',
                  alignContent: 'center', paddingRight: '30px', cursor: 'pointer'
                }}
              >
                <img src={IconRight} />
              </div>
              <div
                onClick={() => {
                  if(x != 0){
                    setX((prev) => (prev + 335));
                  }
                }}
                style={{ position: 'absolute', left: "25px", top: '0px', zIndex: '1', height: '100%', alignContent: 'center', cursor: 'pointer' }}

              >
                <img src={IconLeft} />
              </div>
              <h4 className="font-bold text-xl mb-6">Valoraciones de {companyData.title}</h4>
              <div
                style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  gridAutoColumns: '335px',
                  gap: '10',
                  overflowX: 'hidden'
                }}
              // className='container-carousel'
              >

                {companyData.reviewCards && companyData.reviewCards.length > 0 ? (
                  companyData.reviewCards.map((review, index) => (
                    <motion.div
                      className="box"
                      animate={{ x, y, rotate }}
                      transition={{ type: "spring" }}
                    >
                      <ReviewCard
                        key={index}
                        name={review.name}
                        jobTitle={review.jobTitle}
                        rating={review.rating}
                        pros={review.pros}
                        cons={review.cons}
                        review={review.review}
                        avatarUrl={review.avatarUrl}
                        timeAgo={review.timeAgo}
                      />
                    </motion.div>
                  ))
                ) : (
                  <p>No hay valoraciones disponibles.</p>
                )}
              </div>
            </div>
          </Col>
          <Col xxl={4} xl={5} lg={6} md={8} sm={24} xs={24} className="px-6">
            <CompanySidebar />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CompanyDetail;
