import React, { useState } from "react";
import { Table, Button } from "antd";
import { PlusOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import "./OpenEstilos.css"; // Asegúrate de importar el CSS

interface Offer {
  key: string;
  name: string;
  inscritos: number;
}

const data: Offer[] = [
  { key: "1", name: "Reponedor de supermercado", inscritos: 1234 },
  { key: "2", name: "Mozo de almacén", inscritos: 320 },
  { key: "3", name: "Administrativo", inscritos: 890 },
  // Añadir datos adicionales con claves únicas
  { key: "4", name: "Cajero", inscritos: 450 },
  { key: "5", name: "Gerente", inscritos: 1200 },
  { key: "6", name: "Secretario", inscritos: 760 },
];

const OpenOffers: React.FC = () => {
  const [nameSortOrder, setNameSortOrder] = useState<"ascend" | "descend" | null>(null);
  const [inscritosSortOrder, setInscritosSortOrder] = useState<"ascend" | "descend" | null>(null);

  const handleSort = (columnKey: string) => {
    if (columnKey === "name") {
      setNameSortOrder(nameSortOrder === "ascend" ? "descend" : "ascend");
      setInscritosSortOrder(null); // Reset other column's sort order
    } else if (columnKey === "inscritos") {
      setInscritosSortOrder(inscritosSortOrder === "ascend" ? "descend" : "ascend");
      setNameSortOrder(null); // Reset other column's sort order
    }
  };

  const columns = [
    {
      title: (
        <div onClick={() => handleSort("name")} className="flex items-center cursor-pointer h-[6px]">
          <h1 className="font-bold text-caption">
            Nombre {nameSortOrder === "ascend" ? <UpOutlined className="ml-1" /> : <DownOutlined className="ml-1" />}
          </h1>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 297,
      render: (text: string) => (
        <a className="font-bold text-blue3 text-body-sm flex items-center" style={{ width: "297px", height: "7px" }}>
          {text}
        </a>
      ),
    },
    {
      title: (
        <div onClick={() => handleSort("inscritos")} className="flex items-center cursor-pointer h-[6px]">
          <h1 className="font-bold text-caption ml-[-8px]">
            N° inscritos{" "}
            {inscritosSortOrder === "ascend" ? <UpOutlined className="ml-1" /> : <DownOutlined className="ml-1" />}
          </h1>
        </div>
      ),
      dataIndex: "inscritos",
      key: "inscritos",
      width: 102,
      render: (text: number) => (
        <span className="text-body-sm flex items-center font-bold text-[#5B5B5B] justify-end pr-[19px]" style={{ width: "102px", height: "7px" }}>
          {text.toLocaleString()}
        </span>
      ),
    },
  ];

  return (
    <div className="px-[15px]">
      <h3 className="font-bold text-lg pb-[24px]">Ofertas abiertas</h3>
      <div style={{ maxHeight: "148px", overflow: "hidden" }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          rowClassName={(record, index) => (index % 2 === 0 ? "bg-[#F2F2F2]" : "bg-[#E6E6E6]")}
          scroll={{ y: 200 }} // Fijar el encabezado y permitir desplazamiento vertical
          className="custom-table" // Clase personalizada para estilos adicionales
        />
      </div>
      <Button icon={<PlusOutlined />} className="mt-[24px] bg-blue3 text-white">
        Nueva oferta
      </Button>
    </div>
  );
};

export default OpenOffers;
