import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Button } from 'antd'; // Importa el componente Button de Ant Design
import logo from '../../../assets/logo/logo.png';
import IconNotification from '../../../assets/icons/notification.svg';
import '../../styles/pages/principalNav/PrincipalNav.css';
import ControlPanel from '../../../pages/controlPanel/ControlPanel.tsx';
import SearchCV from '../../../pages/searchCV/SearchCV.tsx';
import Offers from '../../../pages/offers/Offers.tsx';

const PrincipalNav = () => {
  // Obtener la ubicación actual
  const location = useLocation();

  // Función para verificar si una ruta está activa
  const isActive = (path) => {
    return location.pathname.startsWith(path);
  };

  return (
    <div className="principal-nav2">
      <header className="principal-nav-header">
      <Link to="/home">
          <img src={logo} alt="Merere Logo" className="principal-nav-logo mb-4" />
        </Link>
        <nav className="principal-nav-nav">
          <Link
            to="/controlPanel"
            className={`principal-nav-link ${isActive('/controlPanel') ? 'active' : ''}`}
          >
            Panel de Control
          </Link>
          <Link
            to="/searchCV"
            className={`principal-nav-link ${isActive('/searchCV') ? 'active' : ''}`}
          >
            Buscar CV
          </Link>
          <Link
            to="/offers"
            className={`principal-nav-link ${isActive('/offers') ? 'active' : ''}`}
          >
            Ofertas
          </Link>
        </nav>
        <div className="principal-nav-action-buttons flex space-x-2">
          <Button className="principal-nav-bell-button" icon={<img src={IconNotification} alt="Notification" />} />
          <Button className="principal-nav-notify-button">Permite que te valoren</Button>
        </div>
      </header>

      <Routes>
        <Route path="/controlPanel" element={<ControlPanel />} />
        <Route path="/searchCV" element={<SearchCV />} />
        <Route path="/offers" element={<Offers />} />
      </Routes>
    </div>
  );
};

export default PrincipalNav;
