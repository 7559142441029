// components/ProfileInfo.tsx
import React from "react";
import { Row, Col } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";

interface ProfileInfoProps {
  name: string;
  lastNameP: string;
  lastNameS: string;
  aboutMe: string;
  skills: string[];
  experience: {
    title: string;
    company: string;
    other: string;
    location: string;
    startDate: string;
    endDate: string;
    totalDate: string;
    description: string;
    ratings: string;
    tags: string;
  }[];
  education: {
    degree: string;
    school: string;
    others: string;
    location: string;
    startDate: string;
    endDate: string;
    totalDates: string;
    description: string;
    rating: string;
    tag: string;
  }[];
  languages: { name: string; level: string }[];
}
const handleEditClick = () => {
  console.log("Edit button clicked");
  // Aquí puedes añadir la lógica para abrir un modal o redirigir a la página de edición
};

const handleAddClick = () => {
  console.log("Add button clicked");
  // Aquí puedes añadir la lógica para abrir un modal o redirigir a la página de agregar
};
const ProfileInfo: React.FC<ProfileInfoProps> = ({
  name,
  lastNameP,
  lastNameS,
  aboutMe,
  skills,
  experience,
  education,
  languages,
}) => (
  <div>
    <div className="pb-10">
      <h1 className="text-heading-md font-bold">Perfil</h1>
    </div>
    <div className="pb-10">
      <h1 className="text-heading-x1 font-bold pb-3">
        Información de perfil
        <EditOutlined
          onClick={handleEditClick}
          className="text-sky-blue0 pl-3"
        />
      </h1>
      <h1 className="text-body-md font-medium text-green22">{name}</h1>
      <h1 className="text-body-md font-medium text-green22">{lastNameP}</h1>
      <h1 className="text-body-md font-medium text-green22">{lastNameS}</h1>
    </div>

    <div className="pb-10">
      <h1 className="text-heading-x1 font-bold pb-3">
        Sobre mí
        <EditOutlined
          onClick={handleEditClick}
          className="text-sky-blue0 pl-3"
        />
      </h1>
      <h1 className="text-body-md font-medium text-green22 text-justify">{aboutMe}</h1>
    </div>

    <div className="pb-10">
      <h1 className="text-heading-x1 font-bold pb-3">
        Habilidades
        <EditOutlined
          onClick={handleEditClick}
          className="text-sky-blue0 pl-3"
        />
        <PlusOutlined
          onClick={handleAddClick}
          className="text-sky-blue0 pl-3"
        />
      </h1>
      <h1 className="text-body-md font-bold">{skills.join(", ")}</h1>
    </div>

    <div className="pb-10">
      <h1 className="text-heading-x1 font-bold pb-3">
        Experiencia laboral
        <EditOutlined
          onClick={handleEditClick}
          className="text-sky-blue0 pl-3"
        />
        <PlusOutlined
          onClick={handleAddClick}
          className="text-sky-blue0 pl-3"
        />
      </h1>
      <Row gutter={[16, 16]}>
        {experience.map((job, index) => (
          <Col key={index} xs={24} sm={24} lg={8}>
            <h1 className="text-body-md font-bold pb-1">{job.title}</h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.company} | {job.other}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.location}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.startDate} | {job.endDate} | {job.totalDate}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.description}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.ratings}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.tags}
            </h1>
          </Col>
        ))}
      </Row>
    </div>

    <div className="pb-10">
      <h1 className="text-heading-x1 font-bold pb-3">
        Educación
        <EditOutlined
          onClick={handleEditClick}
          className="text-sky-blue0 pl-3"
        />
        <PlusOutlined
          onClick={handleAddClick}
          className="text-sky-blue0 pl-3"
        />
      </h1>
      <Row gutter={[16, 16]}>
        {education.map((edu, index) => (
          <Col key={index} xs={24} sm={24} lg={8}>
            <h1 className="text-body-md font-bold pb-1">{edu.degree}</h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {edu.school} | {edu.others}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {edu.location}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {edu.startDate} | {edu.endDate} | {edu.totalDates}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {edu.description}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {edu.rating}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {edu.tag}
            </h1>
          </Col>
        ))}
      </Row>
    </div>

    <div className="pb-10">
      <h1 className="text-heading-x1 font-bold pb-3">
        Idiomas
        <EditOutlined
          onClick={handleEditClick}
          className="text-sky-blue0 pl-3"
        />
        <PlusOutlined
          onClick={handleAddClick}
          className="text-sky-blue0 pl-3"
        />
      </h1>
      <Row gutter={[16, 5]}>
        {languages.map((lang, index) => (
          <Col key={index} span={16}>
            <h1 className="text-body-md font-medium text-green22 pr-1 inline-block">
              {lang.name}
            </h1>
            <span className="text-body-md font-bold text-green22">
              {lang.level}
            </span>
          </Col>
        ))}
      </Row>
    </div>
  </div>
);

export default ProfileInfo;
