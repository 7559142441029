import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import CardEmpleo from '../../../components/cards/CardEmployment.tsx';
import InformationNotInterested from '../../../components/pages/myApplications/applications/InformationApplication.tsx';
import FilterButtons from '../../../components/pages/myApplications/applications/FilterButtonsApplications.tsx';

const CardEmpleoExample: React.FC = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const [appliedCards, setAppliedCards] = useState<{ [key: number]: boolean }>({ 0: true, 1: true, 2: true });
  const [loadingCards, setLoadingCards] = useState<{ [key: number]: boolean }>({ 0: true, 1: true, 2: true });
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);

  // Simulación de carga de datos inicial para las tarjetas
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingCards({ 0: false, 1: false, 2: false });
    }, 2000); // Simula 2 segundos de carga

    return () => clearTimeout(timer);
  }, []);

  const cardsData = [
    {
      exampleProps: {
        companyName: "Supermercados Carrefour",
        jobTitle: "Reponedor de supermercado",
        location: "Campo de Criptana",
        salary: "18.000€",
        schedule: "Parcial",
        ratings: "20 valoraciones",
        activeOffers: "90 ofertas activas",
        followers: "450 Seguidores",
        description: "Importante cadena de alimentación busca incorporar un/a dependiente/a reponedor/a en Fuerteventura...",
        postedTime: "Subida hace 23h",
        applied: false,
        styleType: 'type4',
        currentStep: 2
      },
      informationProps: {
        title: "Reponedor de supermercado",
        company: "Supermercados Carrefour",
        location: "Campo de Criptana",
        employmentType: "Parcial",
        salary: "18.000€",
        comments: 20,
        applicantsCount: "Inscritos",
        introText: "En Carrefour consideramos que todas las personas tienen un talento que ofrecer. Buscamos personas que empaticen con los clientes, compartan nuestros valores y estén dispuestas a probar nuevos retos. Si desbordas energía, te gusta que todo esté en su sitio y te encanta trabajar codo con codo con tus compañeros, trae tu talento a LOGÍSTICA.",
        requirements: [
          "Inglés: Nivel B1",
          "Incorporación: inmediata",
          "Jornada: Tiempo parcial, mañana",
          "Qué harás día a día"
        ],
        responsibilities: [
          "Cargar y descargar las mercancías de los camiones, comprobar su correcto estado, y chequear los correspondientes albaranes para evitar posibles errores.",
          "Poner la mercancía a disposición del cliente en su correspondiente punto de venta en la tienda, así como realizar las devoluciones de mercancía al almacén en caso de que no sea necesaria en el punto de venta, con el objetivo de garantizar el stock de mercancía en el punto de ventas.",
          "Estás disponible para ayudar a los compañeros del departamento y otras áreas de la tienda cuando sea necesario.",
          "Te comprometes con todas las reglas y requisitos de Carrefour en materia de salud, seguridad y protección, y ayudas activamente a los compañeros a evitar cualquier incidente en el que puedan verse implicados colaboradores, clientes, equipos o instalaciones."
        ],
        extraText: [
          "Porque formas parte de la gran familia CARREFOUR queremos compartir contigo los momentos importantes, por ello celebramos aniversarios, fiestas, Teams Buildings, la llegada del verano, regalo de Navidad. ¡Siempre hay un motivo para sonreír!"
        ],
        extraText2: [
          "¡Muchos más te están esperando, ¡descúbrelos!"
        ],
        postedTime: "Subida hace 23h"
      }
    },
    {
      exampleProps: {
        companyName: "Banco Santander",
        jobTitle: "Asesor Financiero",
        location: "Madrid",
        salary: "30.000€",
        schedule: "Completo",
        ratings: "50 valoraciones",
        activeOffers: "30 ofertas activas",
        followers: "1200 Seguidores",
        description: "Banco líder en el sector financiero busca incorporar un asesor financiero en su sede de Madrid...",
        postedTime: "Subida hace 2 días",
        applied: false,
        styleType: 'type4',
        currentStep: 3
      },
      informationProps: {
        title: "Asesor Financiero",
        company: "Banco Santander",
        location: "Madrid",
        employmentType: "Completo",
        salary: "30.000€",
        comments: 50,
        applicantsCount: "Inscritos",
        introText: "En Banco Santander buscamos personas apasionadas por el mundo financiero y con ganas de crecer profesionalmente. Si te apasiona el trato con el cliente y tienes conocimientos en finanzas, ¡únete a nuestro equipo!",
        requirements: [
          "Licenciatura en Finanzas, Economía o similar",
          "Experiencia mínima de 2 años en el sector",
          "Disponibilidad inmediata"
        ],
        responsibilities: [
          "Asesorar a los clientes sobre productos financieros y de inversión.",
          "Realizar estudios de mercado y análisis financieros.",
          "Gestionar y ampliar la cartera de clientes."
        ],
        extraText: [
          "Ofrecemos formación continua y oportunidades de desarrollo profesional.",
          "Ambiente de trabajo dinámico y proactivo."
        ],
        extraText2: [
          "¡Muchos más te están esperando, ¡descúbrelos!"
        ],
        postedTime: "Subida hace 2 días"
      }
    },
    {
      exampleProps: {
        companyName: "Tech Solutions",
        jobTitle: "Desarrollador Web",
        location: "Barcelona",
        salary: "45.000€",
        schedule: "Completo",
        ratings: "35 valoraciones",
        activeOffers: "15 ofertas activas",
        followers: "800 Seguidores",
        description: "Empresa tecnológica en expansión busca desarrolladores web con experiencia en React y Node.js...",
        postedTime: "Subida hace 1 semana",
        applied: false,
        styleType: 'type4',
        currentStep: 1
      },
      informationProps: {
        title: "Desarrollador Web",
        company: "Tech Solutions",
        location: "Barcelona",
        employmentType: "Completo",
        salary: "45.000€",
        comments: 35,
        applicantsCount: "Inscritos",
        introText: "En Tech Solutions creemos en el poder de la tecnología para cambiar el mundo. Buscamos desarrolladores apasionados por la programación y con ganas de innovar. Si tienes experiencia en desarrollo web y quieres formar parte de un equipo dinámico, ¡únete a nosotros!",
        requirements: [
          "Experiencia en desarrollo con React y Node.js",
          "Conocimientos en bases de datos SQL y NoSQL",
          "Capacidad para trabajar en equipo"
        ],
        responsibilities: [
          "Desarrollar y mantener aplicaciones web.",
          "Trabajar en estrecha colaboración con el equipo de diseño y producto.",
          "Implementar nuevas funcionalidades y mejoras en las aplicaciones existentes."
        ],
        extraText: [
          "Ofrecemos salario competitivo y beneficios sociales.",
          "Posibilidad de trabajo remoto."
        ],
        extraText2: [
          "¡Muchos más te están esperando, ¡descúbrelos!"
        ],
        postedTime: "Subida hace 1 semana"
      }
    }
  ];

  const handleCardClick = (index: number) => {
    setLoadingInfo(true);
    setSelectedCardIndex(index);

    setTimeout(() => {
      setLoadingInfo(false);
    }, 2000); // Simula 2 segundos de carga
  };

  const handleApply = (index: number | null) => {
    if (index !== null) {
      setAppliedCards((prev) => ({
        ...prev,
        [index]: !prev[index]
      }));
    }
  };

  const handleCategoriaClick = (valor: string) => {
    alert(`Categoría seleccionada: ${valor}`);
  };

  const categorias = [
    { valor: 'todas-las-candidaturas', etiqueta: 'Todas las candidaturas' },
    { valor: 'solicitud-enviada', etiqueta: 'Solicitud enviada' },
    { valor: 'Visto-por-la-empresa', etiqueta: 'Visto por la empresa' },
    { valor: 'cv-descargado', etiqueta: 'CV descargado' },
    { valor: 'avanzas-en-el-proceso', etiqueta: 'Avanzas en el proceso' },
    { valor: 'descartado', etiqueta: 'Descartado' },
  ];

  return (
    <div style={{ padding: '10px' }}>
      <FilterButtons
        categorias={categorias}
        alClicEnCategoria={handleCategoriaClick}
      />
      <Row gutter={[16, 16]}>
        <Col xs={24} md={10}>
          {cardsData.map((card, index) => (
            <div
              key={index}
              // Aquí se añade la clase para manejar el sombreado y la selección de la card
              className={`cursor-pointer mb-5 p-4 rounded-lg transition-shadow duration-300 ${
                selectedCardIndex === index
                  ? "shadow-lg" // Sombra intensa si la card está seleccionada
                  : "hover:shadow-md" // Sombra leve cuando se hace hover sobre la card
              }`}
              onClick={() => handleCardClick(index)}
            >
              <CardEmpleo
                {...card.exampleProps}
                applied={!!appliedCards[index]}
                loading={loadingCards[index]}
                currentStep={card.exampleProps.currentStep} // Pass the currentStep prop
              />
            </div>
          ))}
        </Col>
        <Col xs={24} md={14}>
          {selectedCardIndex !== null && (
            <InformationNotInterested
              {...cardsData[selectedCardIndex].informationProps}
              applied={!!appliedCards[selectedCardIndex]}
              loading={loadingInfo}
              onApply={() => handleApply(selectedCardIndex)}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CardEmpleoExample;