import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import { Row, Col, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Header from "../../components/pages/principalNav/HeaderOffers.tsx";
import Setting from "../../components/pages/offers/SidebarOffers.tsx";
import CardOffers from "../../components/pages/offers/CardOffers.tsx";
import IconSearch from "../../assets/icons/search.svg";
import '../../components/styles/pages/principalNav/PrincipalNav.css';

const Offers: React.FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [loading, setLoading] = useState(true); // State to manage loading

  // Simulate data fetching with useEffect
  useEffect(() => {
    const fetchData = () => {
      // Simulate a data fetch
      setTimeout(() => {
        setLoading(false); // Set loading to false after data fetch
      }, 2000); // 2 seconds delay
    };

    fetchData();
  }, []);

  const cardProps = [
    {
      id: 1,
      title: "Reponedor",
      subtitle: "Reponedor de supermercado",
      location: "Alcazar de San Juan",
      activeDate: "Activa desde 20/3/2024",
      inscritos: 35,
      seleccionados: 10,
      descartados: 3,
    },
    {
      id: 2,
      title: "Mozo de almacén",
      subtitle: "Reponedor de supermercado",
      location: "Alcazar de San Juan",
      activeDate: "Activa desde 20/3/2024",
      inscritos: 35,
      seleccionados: 10,
      descartados: 3,
    },
    {
      id: 3,
      title: "Administrativo",
      subtitle: "Reponedor de supermercado",
      location: "Alcazar de San Juan",
      activeDate: "Activa desde 20/3/2024",
      inscritos: 35,
      seleccionados: 10,
      descartados: 3,
    },
    {
      id: 4,
      title: "Cajero en Criptana",
      subtitle: "Cajero de supermercado",
      location: "Campo de Criptana",
      activeDate: "Activa desde 20/3/2024",
      inscritos: 35,
      seleccionados: 10,
      descartados: 3,
    },
  ];

  const handleCardClick = (id: number) => {
    // Redirige a la página de detalles de la oferta con el ID correspondiente
    navigate(`/offers/${id}`);
  };

  return (
    <>
      <Header />
      <div className="bg-white px-[24px] py-[48px]">
        <div className="flex">
          <div style={{ width: "234px", backgroundColor: "white" }} className="p-0">
            <Setting />
          </div>
          <div className="flex-1 pl-[24px]">
                  <>
                    <Row gutter={[16, 16]}>
                      <Col xl={12} md={12}>
                        <div
                          style={{
                            textAlign: "right",
                            display: "flex",
                            justifyContent: "left",
                          }}
                        >
                          <Input
                            prefix={<img src={IconSearch} width={24} height={24} />}
                            placeholder="Buscar oferta"
                            className="Input-Filter-Employment w-full md:w-[296px] custom-input-company px-2 rounded"
                          />
                          
                          <Button className="bg-blue3 text-white w-[102px] ml-[18px] principal-nav-notify-button2 rounded-[4px] ">
                            Buscar
                          </Button>
                        </div>
                      </Col>
                      <Col xl={12} md={12} className="flex justify-end">
                        <div className="principal-nav-action-buttons flex space-x-2">
                          <Button
                            className="principal-nav-notify-button md:w-[327px]"
                            onClick={() => navigate("/offers/createOffer")} // Change path to subroute
                          >
                            <PlusOutlined />
                            Crear una nueva oferta de empleo
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={24}>
                        <div className="pt-3">
                          <h2 className="text-heading-md font-bold mb-4">Listado de ofertas abiertas</h2>
                          {cardProps.slice(0, 3).map((props, index) => (
                            <div className="pb-[8px]" key={index} onClick={() => handleCardClick(props.id)}>
                              <CardOffers {...props} loading={loading} /> {/* Pass loading state */}
                            </div>
                          ))}
                          <h2 className="text-heading-md font-bold mb-4 mt-[48px]">Listado de guardadas (borrador)</h2>
                          <div className="pb-[8px]" onClick={() => handleCardClick(props.id)}>
                            <CardOffers {...cardProps[3]} loading={loading} /> {/* Pass loading state */}
                          </div>
                          <h2 className="text-heading-md font-bold mb-4 mt-[48px]">Listado de finalizadas</h2>
                          {cardProps.slice(0, 3).map((props, index) => (
                            <div className="pb-[8px]" key={index} onClick={() => handleCardClick(props.id)}>
                              <CardOffers {...props} loading={loading} /> {/* Pass loading state */}
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </>
          
          </div>
        </div>
      </div>
    </>
  );
};

export default Offers;