// components/Portfolio.tsx
import React from "react";
import { Typography, Row, Col } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";

const { Title, Text } = Typography;

interface Project {
  name: string;
  description: string;
  skills: string;
  link: string;
  logoUrl: string; // Añadido para la URL del logo
}

interface PortfolioProps {
  projects: Project[];
}

const handleEditClick = () => {
  console.log("Edit button clicked");
  // Aquí puedes añadir la lógica para abrir un modal o redirigir a la página de edición
};

const handleAddClick = () => {
  console.log("Add button clicked");
  // Aquí puedes añadir la lógica para abrir un modal o redirigir a la página de agregar
};

const Portfolio: React.FC<PortfolioProps> = ({ projects }) => (
  <div className="pb-8">
    <h1 className="text-heading-md font-bold pb-3 flex items-center">
      Portafolio
      <EditOutlined
        onClick={handleEditClick}
        className="text-sky-blue0 pl-3 cursor-pointer"
      />
      <PlusOutlined
        onClick={handleAddClick}
        className="text-sky-blue0 pl-3 cursor-pointer"
      />
    </h1>
    {projects.map((project, index) => (
      <Row key={index} className="mb-8">
        {/* Primera Columna */}
        <Col xs={24} sm={24} lg={16} className="mb-8">
          {/* Título del Proyecto */}
          <h2 className="text-heading-x1 font-bold mb-4 flex items-center">
            {project.name}
            <EditOutlined
              onClick={handleEditClick}
              className="text-sky-blue0 pl-2 cursor-pointer"
            />
          </h2>

          {/* Logo y Detalles del Proyecto */}
          <Row className="w-full">
            {/* Logo del Proyecto */}
            <Col 
              xs={24} sm={9} lg={8}
              className="flex justify-center items-center mb-4 sm:mb-0"
            >
              <div className="mr-4">
                <img
                  src={project.logoUrl}
                  className="w-205 border-blue0 object-contain" // Tamaño ajustado
                />
              </div>
            </Col>

            {/* Detalles del Proyecto */}
            <Col xs={24} sm={16} lg={16}>
              <div>
                <p className="text-body-md font-bold mb-1">
                  {project.description}
                </p>
                <p className="text-body-md font-medium">{project.skills}</p>
                <a
                  href={project.link}
                  className="text-body-md font-medium text-blue-600 hover:underline"
                >
                  {project.link}
                </a>
              </div>
            </Col>
          </Row>
        </Col>

        {/* Segunda Columna */}
        <Col xs={24} sm={24} lg={8} className="flex pl-4 items-center">
          <div className=" ">
            <h2 className="text-heading-x1 font-bold flex items-center mb-2">Título del proyecto
            <EditOutlined
              onClick={handleEditClick}
              className="text-sky-blue0 pl-2 cursor-pointer"
            />
            </h2>
            <ul className="list-none space-y-1">
              <li>
                <strong>Descripción:</strong> {project.description}
              </li>
              <li>
                <strong>Habilidades:</strong> {project.skills}
              </li>
              <li>
                <strong>Sitio web:</strong>{" "}
                <a
                  href={project.link}
                  className="text-blue-600 hover:underline"
                >
                  {project.link}
                </a>
              </li>
              <li>
                <strong>Imágenes | vídeos:</strong> Ejemplo
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    ))}
  </div>
);

export default Portfolio;
