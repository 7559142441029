import React, { useState } from "react";
import { Table } from "antd";
import CheckboxC from "../../../components/checkbox/CheckboxProps.tsx";
import RatingStar from "../searchCV/RatingStar.tsx"; // Importar RatingStar

interface Column {
  title: string;
  dataIndex: string;
  key: string;
  render?: (text: any, record: any) => JSX.Element | string;
}

interface DataType {
  id: string;
  nombre: string;
  valoraciones: number;
  numReviews: number;
  aptitudes: {
    role: string;
    language: string;
    level: string;
  };
  softSkills: string;
  ubicacion: string;
  estudios: string;
  experiencia: string;
  rol: string;
}

interface OfferTableProps {
  columns: Column[];
  data: DataType[];
}

const OfferTable: React.FC<OfferTableProps> = ({ columns, data }) => {
  const [selectedKeys, setSelectedKeys] = useState<React.ReactText[]>([]);

  // Function to handle checkbox change
  const handleCheckboxChange = (id: React.ReactText, checked: boolean) => {
    setSelectedKeys((prevSelectedKeys) =>
      checked
        ? [...prevSelectedKeys, id]
        : prevSelectedKeys.filter((key) => key !== id)
    );
  };

  // Define the columns in the desired order
  const columnsWithCheckbox: Column[] = [
    {
      title: "",
      dataIndex: "checkbox",
      key: "checkbox",
      render: (text, record) => (
        <CheckboxC
          checked={selectedKeys.includes(record.id)}
          onChange={(e) => handleCheckboxChange(record.id, e.target.checked)}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <div className="font-normal text-caption text-green32">
              {record.nombre}
            </div>
            <div className="font-bold text-body-sm text-green32">
              {record.rol}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Valoraciones",
      dataIndex: "valoraciones",
      key: "valoraciones",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <RatingStar value={record.valoraciones} /> {/* Usa el componente RatingStar */}
          <span className="font-bold text-body-sm ml-[4px]">
            {`${record.valoraciones}`}
          </span>
          <span className="font-normal text-body-sm text-green32 ml-[4px]">
            ({record.numReviews})
          </span>
        </div>
      ),
    },
    {
      title: "Aptitudes",
      dataIndex: "aptitudes",
      key: "aptitudes",
      render: (aptitudes) => (
        <div className="font-medium text-caption text-green32">
          {`${aptitudes.role} ${aptitudes.language} `}
          <span className="font-bold text-caption text-green32">
            {aptitudes.level}
          </span>
        </div>
      ),
    },
    {
      title: "Soft Skills",
      dataIndex: "softSkills",
      key: "softSkills",
      render: (text) => (
        <div className="cursor-pointer text-caption text-blue3">{text}</div>
      ),
    },
    {
      title: "Ubicación",
      dataIndex: "ubicacion",
      key: "ubicacion",
      render: (text) => (
        <div className="font-normal text-body-sm text-green32">{text}</div>
      ),
    },
    {
      title: "Estudios",
      dataIndex: "estudios",
      key: "estudios",
      render: (text) => (
        <div className="font-medium text-body-sm text-green32">{text}</div>
      ),
    },
    {
      title: "Experiencia",
      dataIndex: "experiencia",
      key: "experiencia",
      render: (text) => (
        <div className="font-medium text-body-sm text-green32">{text}</div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columnsWithCheckbox}
        dataSource={data}
        pagination={false}
        rowKey={(record) => record.id} // Assuming each row has a unique `id` field
        className="offer-table"
      />
      <style>{`
        .offer-table .ant-table-thead > tr > th {
          background-color: white; /* Header background color */
          color: #5f5f5f; /* Header text color */
          font-weight: normal;
          font-size: 12px;
          border-bottom: 1px solid #5F5F5F; /* Border color under header */
        }

        .offer-table .ant-table-thead > tr > th,
        .offer-table .ant-table-tbody > tr > td {
          padding: 12px; /* Padding */
          border-right: none; /* Remove borders from table body */
        }
      `}</style>
    </div>
  );
};

export default OfferTable;
