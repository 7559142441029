import React from "react";
import { Card, Col, Row, Skeleton } from "antd";
import "tailwindcss/tailwind.css";
import ButtonText from "../../../components/button/ButtonText.tsx"; // Make sure this path is correct

interface CardOffersProps {
  title: string;
  subtitle: string;
  location: string;
  activeDate: string;
  inscritos: number;
  seleccionados: number;
  descartados: number;
  loading: boolean; // Add a loading prop to handle the loading state
  onClick?: () => void; // Add the onClick prop
}

const CardOffers: React.FC<CardOffersProps> = ({
  title,
  subtitle,
  location,
  activeDate,
  inscritos,
  seleccionados,
  descartados,
  loading,
  onClick, // Include the onClick prop
}) => (
  <div onClick={onClick} className="cursor-pointer"> {/* Add onClick to the container and cursor-pointer for the pointer cursor */}
    <Card className="bg-white shadow-md border-greene rounded-[4px]">
      <Skeleton loading={loading} active>
        <div className="flex items-center justify-between mb-[-4px] mt-[-5px]">
          <ButtonText
            buttons={[
              {
                type: "link",
                label: title,
                size: "middle",
                textColor: "#006497",
                fontWeight: "bold",
              },
            ]}
          />
        </div>
        <div className="text-body-md mb-[4px]">{subtitle}</div>
        <div className="text-body-sm font-normal mb-[4px]">
          {location} |{" "}
          <ButtonText
            buttons={[
              {
                type: "link",
                label: activeDate,
                size: "small",
                textColor: "#006497",
                fontWeight: "normal",
              },
            ]}
          />
        </div>
        <Row gutter={[10, 10]} className="">
          <Col xs={24} sm={6} md={4} lg={2}>
            <div className="font-bold text-caption">{inscritos} inscritos</div>
          </Col>
          <Col xs={24} sm={8} md={5} lg={3}>
            <div className="font-bold text-caption">{seleccionados} seleccionados</div>
          </Col>
          <Col xs={24} sm={8} md={5} lg={3}>
            <div className="font-bold text-caption">{descartados} descartados</div>
          </Col>
        </Row>
      </Skeleton>
    </Card>
  </div>
);

export default CardOffers;
