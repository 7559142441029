import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Divider } from "antd";
import Header from "../../../components/pages/principalNav/HeaderOffers.tsx";
import OffersProfileInfo from "../../../components/pages/offers/offersProfile/OffersProfileInfo.tsx";
import SidebarOffers from "../../../components/pages/offers/offersProfile/SidebarOffersProfile.tsx";
import Avatar1 from "../../../assets/img/offers/Avatar.png";
import Icon1 from "../../../assets/img/offers/icon1_offers.svg";
import Icon2 from "../../../assets/img/offers/icon2_offers.svg";

const OffersProfile: React.FC = () => {
  const { id } = useParams();
  const offerId = parseInt(id, 10);

  const profileInfo = {
    1: [
      {
        avatarUrl: Avatar1,
        name: "Elena Rodriguez",
        role: "Reponedor",
        location: "Alcazar de San Juan",
        rating: 3.2,
        reviewsLink: "Ver valoraciones",
        experiences: ["Cajero de supermercado | Supermercados Carrefour | jun. 2022 - abr. 2023 (14 meses)", "Ayudante de charcutería | Eroski | may. 2023 - feb. 2024 (15 meses)"],
        education: ["Bachillerato de arte | Escuela de artes de Salamanca | jun. 2022 - abr. 2023", "Curso de atención al cliente y ventas | Supermercados Carrefour | may. 2023 - feb. 2024"],
        tags: ["Cajero", "Supermercado"],
        language: ["Ingles C1", "Catalan B2"],
        status: ["Búsqueda activa | Remoto admitido | Jornada completa"],
        cvLink: " | Ver CV en pdf |",
        killerQuestions: "Killer questions 7/10",
        aptitudes: ["Cajera"],
        onSelectAnswer: (value) => console.log("Respuesta seleccionada:", value)
      },
      {
        avatarUrl: Avatar1,
        name: "Elena Rodriguez",
        role: "Reponedor",
        location: "Alcazar de San Juan",
        rating: 3.2,
        reviewsLink: "Ver valoraciones",
        experiences: ["Cajero de supermercado | Supermercados Carrefour | jun. 2022 - abr. 2023 (14 meses)", "Ayudante de charcutería | Eroski | may. 2023 - feb. 2024 (15 meses)"],
        education: ["Bachillerato de arte | Escuela de artes de Salamanca | jun. 2022 - abr. 2023", "Curso de atención al cliente y ventas | Supermercados Carrefour | may. 2023 - feb. 2024"],
        tags: ["Cajero", "Supermercado"],
        language: ["Ingles C1", "Catalan B2"],
        status: ["Búsqueda activa | Remoto admitido | Jornada completa"],
        cvLink: " | Ver CV en pdf |",
        killerQuestions: "Killer questions 7/10",
        aptitudes: ["Cajera"],
        onSelectAnswer: (value) => console.log("Respuesta seleccionada:", value)
      },
      // Más perfiles...
    ],
    // Otros IDs...
  };

  const currentProfileInfos = profileInfo[offerId];

  return (
    <div>
      <Header />
      <div className="container mx-auto px-4 py-6">
        <Row gutter={16}>
          <Col xs={24} md={8} lg={5}>
            <SidebarOffers />
          </Col>
          <Col xs={24} md={16} lg={19}>
            <div className="mb-6">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="font-bold text-[#006497] text-2xl inline">Oferta</h2>
                  <span className="font-bold text-[#1A1A1A70] text-2xl mx-2">&gt;</span>
                  <span className="font-bold text-black text-2xl inline">Listado de inscritos en la oferta</span>
                </div>
              </div>
              <Divider className="my-4 bg-[#A1A1AA]" />
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h3 className="text-sm font-medium text-black">Título de la oferta</h3>
                  <p className="text-lg font-medium text-black">Responsable de turno en supermercado</p>
                </div>
                <button className="bg-white font-bold text-[#006497] border border-[#006497] py-1 px-3 rounded-md hover:border-2">
                  Guardar búsqueda
                </button>
              </div>

              <div className="flex justify-between items-center mb-4 mt-8">
                <div>
                  <span className="font-bold text-[#1A1A1A70] text-xs">Ordenado por:</span>
                  <div className=" mt-2">
                    <span className="font-bold text-[#00476D] text-xs">RECOMENDACIÓN</span>
                    <span className="font-bold text-[#1A1A1A70] text-xs mx-2">|</span>
                    <span className="font-bold text-[#1A1A1A70] text-xs">KILLER QUESTIONS</span>
                    <span className="text-xs text-[#00476D] ml-1">&#9660;</span>
                    <span className="font-bold text-[#1A1A1A70] text-xs mx-2">|</span>
                    <span className="font-bold text-[#00476D] text-xs">FECHA DE INSCRIPCIÓN</span>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="p-1 border border-[#006497] rounded">
                    <img src={Icon1} alt="Icono 1" className="w-5 h-5" />
                  </div>
                  <img src={Icon2} alt="Icono 2" className="w-5 h-5" />
                </div>
              </div>

              <div className="flex justify-between items-center mb-4">
                <span className="font-bold text-[#1A1A1A70] text-xs mr-2">ACCIONES DE PERFIL:</span>
                <div className="flex space-x-2">
                  <button className="text-[#00476D] font-bold text-xs hover:underline">Añadir a favoritos</button>
                  <span className="font-bold text-xs">|</span>
                  <button className="text-[#00476D] font-bold text-xs hover:underline">Avanzar en la fase</button>
                  <span className="font-bold text-xs">|</span>
                  <button className="text-[#00476D] font-bold text-xs hover:underline">Guardar perfil</button>
                  <span className="font-bold text-xs">|</span>
                  <button className="text-red-500 font-bold text-xs hover:underline">Archivar candidato</button>
                </div>
                <button className="text-[#00476D] font-bold text-xs hover:underline ml-auto">
                  Ver candidatos seleccionados sobre gráfica
                </button>
              </div>
            </div>
            {currentProfileInfos ? (
              <Card className="border-[#81BFEC] bg-[#FBFDFE] rounded-md p-4 mb-4">
                {currentProfileInfos.map((profile, index) => (
                  <React.Fragment key={index}>
                    <OffersProfileInfo {...profile} />
                    {index < currentProfileInfos.length - 1 && <Divider className="bg-black" />}
                  </React.Fragment>
                ))}
              </Card>
            ) : (
              <p>Oferta no encontrada</p>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OffersProfile;
