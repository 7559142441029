import React, { useEffect } from "react";
import { Row, Col, Divider } from "antd";
import KillerQuestions from "../../../components/pages/offers/KillerQuestions/KillerQuestions.tsx";
import OfferDescription from "../../../components/pages/offers/KillerQuestions/SidebarOfferDescription.tsx";
import Header from "../../../components/pages/principalNav/HeaderOffers.tsx";
import icons from '../../../assets/icons/right.svg'
const CreateOffer2: React.FC = () => {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Run only once when the component mounts

  // Example data for the offer description
  const jobDetails = [
    "2 años de experiencia",
    "ESO",
    "Jornada completa",
    "Mañana y tarde",
    "Retail",
  ];

  const skills = ["Office", "Atención al público", "Inglés"];

  const keywords = [
    "Campo de Criptana",
    "Alcázar de San Juan",
    "Herencia",
    "ESO",
    "Más de 2 años de experiencia",
  ];

  return (
    <>
      <Header />
      <div className="bg-white px-[24px] py-[24px]">
      <Row>
          <Col>
          <h1 className="font-bold text-heading-md text-blue3">
          Ofertas <img  src={icons} alt="Right Arrow" className="inline mx-[8px]"/>
        </h1>
          </Col>
          <Col>
            <h1 className="font-bold text-heading-md text-blue3">
              Creación de nueva oferta de empleo <img  src={icons} alt="Right Arrow" className="inline mx-[8px]"/>
            </h1>
          </Col>
          <h1 className="font-bold text-heading-md">Killer questions</h1>
          <Col>
          </Col>
          <Divider className=" bg-grays"></Divider>
        </Row>

        <div className="flex">
          <div className="flex-1">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24}>
                <div className="pt-3">
                  <KillerQuestions />
                </div>
              </Col>
            </Row>
          </div>

          <div
            style={{ width: "234px", backgroundColor: "white" }}
            className="p-0"
          >
            <OfferDescription
              jobTitle="Cajero de supermercado"
              jobLocation="Campo de Criptana, CR"
              salaryRange="18.000-20.000€"
              jobDetails={jobDetails}
              skills={skills}
              keywords={keywords}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOffer2;
