import React, { useState } from "react";
import { Layout, Input, Select, Slider } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import Rating from "../../../rating/Rating.tsx";
import ButtonText from "../../../button/ButtonText.tsx";
import ButtonCom from "../../../button/Button.tsx";
import StyledCheckbox from '../../../checkbox/CheckboxProps.tsx';
import ToggleSwitch from '../../../toggleSwitch/ToggleSwitch.tsx';

const { Sider } = Layout;
const { Option } = Select;

const ratings = [5, 4, 3, 2, 1, 0];
const keywordButtonsText = ["Office", "Inglés", "Administrativo"];
const softSkills = ["Colaborativo"];
const provinces = ["Ciudad Real"];
const towns = ["Campo de Criptana", "Alcazar de San Juan"];
const companies = ["Eroski", "Carrefour", "Aldi", "El Corte Inglés"];
const educationLevels = ["Ninguna", "Graduado escolar", "ESO", "Bachillerato", "Ciclo formativo", "Grado"];
const availability = ["Tiempo completo", "Tiempo parcial", "Mañanas", "Tardes", "Noches"];
const experience = ["Educación", "Eventos y Entretenimiento", "Limpieza", "Retail", "Salud y bienestar", "Servicios a domicilio", "Turismo"];

const Sidebar: React.FC = () => {
  const [openSections, setOpenSections] = useState({
    valoraciones: true,
    palabrasClave: true,
    cualificativos: true,
    ubicacion: true,
    busquedasSugeridas: false,
  });
  
  const [selectedCompanies, setSelectedCompanies] = useState(companies);
  const [experienceYears, setExperienceYears] = useState(6); // Default experience value
  const [keywords, setKeywords] = useState(["Office", "Inglés", "Administrativo"]);
  const [newKeyword, setNewKeyword] = useState("");

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };


  const addKeyword = () => {
    if (newKeyword.trim() !== "" && !keywords.includes(newKeyword.trim())) {
      setKeywords([...keywords, newKeyword.trim()]);
      setNewKeyword("");
    }
  };

  const removeKeyword = (keywordToRemove: string) => {
    setKeywords(keywords.filter(keyword => keyword !== keywordToRemove));
  };

  const addCompany = (company) => {
    if (company && !selectedCompanies.includes(company)) {
      setSelectedCompanies([...selectedCompanies, company]);
    }
  };

  const removeCompany = (company) => {
    setSelectedCompanies(selectedCompanies.filter(c => c !== company));
  };

  return (
    <div className="flex flex-col p-4 bg-white shadow-md rounded-lg">
      <Sider
        width={234}
        style={{ backgroundColor: "white" }}
        className="rounded-lg shadow-md p-5 border border-sky-blue0 shadow-gray-400"
      >
        <h2 className="text-heading-md font-bold text-green42 mb-1">Filtros</h2>
        <h2 className="text-body-md font-bold text-green42">
          Refina tu búsqueda
        </h2>
        <h2 className="text-caption font-bold text-blue3 mb-6 cursor-pointer">
          Eliminar todos los filtros
        </h2>

        {/* Búsqueda de trabajo activa */}
        <div className="mb-4">
          <div className="flex justify-between items-center">
            <h3 className="font-semibold text-[12px] mb-2 text-green42">
              Búsqueda de trabajo activa
            </h3>
            <ToggleSwitch />
          </div>
        </div>

        {/* Valoraciones */}
        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("valoraciones")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Valoraciones
            </h3>
            {openSections.valoraciones ? (
              <CaretUpOutlined />
            ) : (
              <CaretDownOutlined />
            )}
          </div>
          {openSections.valoraciones && (
            <div className="flex flex-col">
              {ratings.map((value) => (
                <StyledCheckbox key={value} value={value}>
                  <Rating value={value} size={20} />
                </StyledCheckbox>
              ))}
              <StyledCheckbox className="mt-4 ">
              <h3 className="font-medium text-[14px] mb-2 text-green42">
              Solo candidatos con mas de 10 valoraciones
            </h3>
              </StyledCheckbox>
            </div>
          )}
        </div>

         {/* Palabras clave */}
         <div className="mb-4">
          <h3 className="font-bold text-heading-sm mb-2 text-green42">Palabras clave</h3>
          <div className="flex mb-4">
            <Input
              placeholder="Escribe aquí..."
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
              className="h-[36px] flex-1"
            />
            <ButtonCom
              buttons={[
                {
                  type: "link",
                  label: "Añadir",
                  border: "1px solid #006497",
                  color: "transparent",
                  size: "small",
                  textColor: "#006497",
                  fontSize: "14px",
                  fontWeight: "bold",
                  onClick: addKeyword,
                },
              ]}
            />
          </div>

          <div className="flex flex-wrap">
            {keywords.map((keyword, index) => (
              <div key={index} className="flex items-center mb-2">
                <ButtonText
                  buttons={[
                    {
                      type: "link",
                      label: keyword,
                      size: "small",
                      textColor: "#006497",
                      color: "white",
                      underline: true,
                      fontWeight: "bold",
                      fontSize: "14px",
                    },
                  ]}
                />
                <span
                  className="ml-2 cursor-pointer text-red-500"
                  onClick={() => removeKeyword(keyword)}
                >
                  X
                </span>
              </div>
            ))}
          </div>
          <p className="italic text-[12px] text-[#1A1A1A] opacity-70 mt-2">
            Escribe "-" antes de la palabra para excluirla de la búsqueda
          </p>
        </div>
        

        {/* Cualificativos */}
        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("cualificativos")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Cualificativos
            </h3>
            {openSections.cualificativos ? (
              <CaretUpOutlined />
            ) : (
              <CaretDownOutlined />
            )}
          </div>
          {openSections.cualificativos && (
            <div>
              <Select
                placeholder="Selecciona soft skills"
                className="w-full mb-4"
              >
                {softSkills.map((skill, index) => (
                  <Option key={index} value={skill}>
                    {skill}
                  </Option>
                ))}
              </Select>

              <div className="flex flex-wrap">
                {softSkills.map((skill, index) => (
                  <ButtonText
                    key={index}
                    buttons={[
                      {
                        type: "link",
                        label: skill,
                        size: "small",
                        textColor: "#006497",
                        color: "white",
                        underline: true,
                        fontWeight: "bold",
                        fontSize: "14px",
                      },
                    ]}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Ubicación */}
        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("ubicacion")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Ubicación
            </h3>
            {openSections.ubicacion ? (
              <CaretUpOutlined />
            ) : (
              <CaretDownOutlined />
            )}
          </div>
          {openSections.ubicacion && (
            <div>
              <div className="mb-4">
                <h4 className="font-bold text-body-md mb-2">Provincia</h4>
                <Select placeholder="Seleccionar provincia" className="w-full">
                  {provinces.map((province, index) => (
                    <Option key={index} value={province}>
                      {province}
                    </Option>
                  ))}
                </Select>
                {provinces.map((province, index) => (
                  <ButtonText
                    key={index}
                    buttons={[
                      {
                        type: "link",
                        label: province,
                        size: "small",
                        textColor: "#006497",
                        color: "white",
                        underline: true,
                        fontWeight: "bold",
                        fontSize: "14px",
                      },
                    ]}
                  />
                ))}
              </div>

              <div className="mb-4">
                <h4 className="font-bold text-body-md mb-2">Población</h4>
                <Select placeholder="Seleccionar población" className="w-full">
                  {towns.map((town, index) => (
                    <Option key={index} value={town}>
                      {town}
                    </Option>
                  ))}
                </Select>
                {towns.map((town, index) => (
                  <ButtonText
                    key={index}
                    buttons={[
                      {
                        type: "link",
                        label: town,
                        size: "small",
                        textColor: "#006497",
                        color: "white",
                        underline: true,
                        fontWeight: "bold",
                        fontSize: "14px",
                      },
                    ]}
                  />
                ))}
              </div>

              <div className="flex items-center">
                <StyledCheckbox value={1}>Solo remoto</StyledCheckbox>
              </div>
            </div>
          )}
        </div>
        {/* Empresas */}
        <div className="mb-4">
          <h3 className="font-bold text-heading-sm mb-2 text-green42">Empresas</h3>
          <div className="flex mb-4">
            <Input
              placeholder="Escribe aquí..."
              className="h-[36px] flex-1"
              onPressEnter={(e) => addCompany(e.target.value)}
            />
            <ButtonCom
              buttons={[
                {
                  type: "link",
                  label: "Añadir",
                  border: "1px solid #006497",
                  color: "transparent",
                  size: "small",
                  textColor: "#006497",
                  fontSize: "14px",
                  fontWeight: "bold",
                  onClick: () => addCompany(document.querySelector('.ant-input').value)
                },
              ]}
            />
          </div>
          <div>
            {selectedCompanies.map((company, index) => (
              <div key={index} className="flex justify-between items-center mb-2">
                <span className={`font-bold ${index % 2 === 1 ? 'text-red-500' : ''}`}>
                  {company}
                </span>
                <ButtonText
                  buttons={[
                    {
                      type: "link",
                      label: "X",
                      size: "small",
                      textColor: "#ff4d4f",
                      color: "transparent",
                      underline: false,
                      fontWeight: "bold",
                      fontSize: "14px",
                      onClick: () => removeCompany(company),
                    },
                  ]}
                />
              </div>
            ))}
            <p className="text-xs text-gray-500 mt-2">
              Escribe "~" antes de la palabra para excluirla de la búsqueda
            </p>
          </div>
        </div>

        {/* Experiencia (años) */}
        <div className="mb-4">
          <h3 className="font-bold text-heading-sm mb-2 text-green42">Experiencia (años)</h3>
          <Slider
            min={0}
            max={10}
            value={experienceYears}
            onChange={(value) => setExperienceYears(value)}
            marks={{ 0: '0', 5: '5', 10: '10' }}
          />
          <StyledCheckbox value={1} className="mt-2">Rockies</StyledCheckbox>
        </div>

        {/* Educación */}
        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("educacion")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">Educación</h3>
            {openSections.educacion ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </div>
          {openSections.educacion && (
            <div>
              {educationLevels.map((level, index) => (
                <StyledCheckbox key={index} value={level}>
                  {level}
                </StyledCheckbox>
              ))}
            </div>
          )}
        </div>

        {/* Disponibilidad */}
          <div className="mb-4">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection("disponibilidad")}
            >
              <h3 className="font-bold text-heading-sm mb-2 text-green42">Disponibilidad</h3>
              {openSections.disponibilidad ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </div>
            {openSections.disponibilidad && (
              <div>
                {availability.map((option, index) => (
                  <StyledCheckbox key={index} value={option}>
                    {option}
                  </StyledCheckbox>
                ))}
              </div>
            )}
          </div>

          {/* Áreas de Experiencia */}
            <div className="mb-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleSection("experiencia")}
              >
                <h3 className="font-bold text-heading-sm mb-2 text-green42">Experiencia en el sector</h3>
                {openSections.experiencia ? <CaretUpOutlined /> : <CaretDownOutlined />}
              </div>
              {openSections.experiencia && (
                <div>
                  {experience.map((area, index) => (
                    <StyledCheckbox key={index} value={area}>
                      {area}
                    </StyledCheckbox>
                  ))}
                </div>
              )}
            </div>

            <div className="mb-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleSection("busquedasSugeridas")}
              >
                <h3 className="font-bold text-heading-sm mb-2 text-green42">
                  Búsquedas sugeridas
                </h3>
                {openSections.busquedasSugeridas ? (
                  <CaretUpOutlined />
                ) : (
                  <CaretDownOutlined />
                )}
              </div>
              {openSections.busquedasSugeridas && (
                <div className="flex flex-col text-bluec">
                  {[
                    "Dedicadas a la logística",
                    "Mejor valoradas de Ciudad Real",
                    "Reclutadores independientes que operan en Ciudad Real",
                    "Especializadas en retail",
                  ].map((sector) => (
                    <div key={sector} className="mb-2 font-bold text-body-md">
                      {sector}
                    </div>
                  ))}
                </div>
              )}
            </div>
      </Sider>
    </div>
  );
};

export default Sidebar;
