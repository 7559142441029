import React, { useState } from "react";
import { Table, Tooltip } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css"; // Asegúrate de que Tailwind CSS está importado
import "./OpenEstilos.css"; // Asegúrate de importar el CSS

interface Registrant {
  key: string;
  name: string;
  offer: string;
  ko: string;
}

const data: Registrant[] = [
  { key: "1", name: "Elena Rodríguez", offer: "Reponedor de supermercado", ko: "7/7 true" },
  { key: "2", name: "Lucía Romero", offer: "Mozo de almacén en la sección de productos perecederos", ko: "3/7 true" },
  { key: "3", name: "Carmen Delgado", offer: "Administrativo en la sección de recursos humanos", ko: "5/7 true" },
  { key: "4", name: "Lucía Romero", offer: "Mozo de almacén", ko: "4/7 true" },
  { key: "5", name: "Carmen Delgado", offer: "Administrativo", ko: "6/7 true" },
  { key: "6", name: "Carlos García", offer: "Cajero", ko: "6/7 true" },
  { key: "7", name: "Laura Pérez", offer: "Gerente", ko: "4/7 true" },
];

const LatestRegistrants: React.FC = () => {
  const [nameSortOrder, setNameSortOrder] = useState<"ascend" | "descend" | null>(null);
  const [offerSortOrder, setOfferSortOrder] = useState<"ascend" | "descend" | null>(null);
  const [koSortOrder, setKoSortOrder] = useState<"ascend" | "descend" | null>(null);

  const handleSort = (columnKey: string) => {
    switch (columnKey) {
      case "name":
        setNameSortOrder(nameSortOrder === "ascend" ? "descend" : "ascend");
        setOfferSortOrder(null); // Reset other column's sort order
        setKoSortOrder(null);
        break;
      case "offer":
        setOfferSortOrder(offerSortOrder === "ascend" ? "descend" : "ascend");
        setNameSortOrder(null);
        setKoSortOrder(null);
        break;
      case "ko":
        setKoSortOrder(koSortOrder === "ascend" ? "descend" : "ascend");
        setNameSortOrder(null);
        setOfferSortOrder(null);
        break;
    }
  };

  const columns = [
    {
      title: (
        <div onClick={() => handleSort("name")} className="flex items-center cursor-pointer h-[6px]">
          <h1 className="font-bold text-caption">
            Nombre {nameSortOrder === "ascend" ? <UpOutlined className="ml-1" /> : <DownOutlined className="ml-1" />}
          </h1>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 182, // Ancho de la primera columna
      render: (text: string) => (
        <Tooltip title={text}>
          <div
            className="font-bold text-blue3 text-body-sm flex items-center"
            style={{
              width: "182px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingRight: "8px", // Agregar relleno a la derecha
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div onClick={() => handleSort("offer")} className="flex items-center cursor-pointer h-[6px]">
          <h1 className="font-bold text-caption">
            Oferta {offerSortOrder === "ascend" ? <UpOutlined className="ml-1" /> : <DownOutlined className="ml-1" />}
          </h1>
        </div>
      ),
      dataIndex: "offer",
      key: "offer",
      width: 107, // Ancho de la segunda columna
      render: (text: string) => (
        <Tooltip title={text}>
          <div
            className="text-body-sm font-bold text-[#5B5B5B]"
            style={{
              width: "107px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingRight: "10px", // Agregar relleno a la derecha
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div onClick={() => handleSort("ko")} className="flex items-center cursor-pointer h-[6px]">
          <h1 className="font-bold text-caption">
            KO {koSortOrder === "ascend" ? <UpOutlined className="ml-1" /> : <DownOutlined className="ml-1" />}
          </h1>
        </div>
      ),
      dataIndex: "ko",
      key: "ko",
      width: 107, // Ancho de la tercera columna
      render: (text: string) => (
        <div
          className="text-body-sm font-bold text-[#5B5B5B]"
          style={{ 
            width: "107px", 
            whiteSpace: "nowrap", 
            overflow: "hidden", 
            textOverflow: "ellipsis", 
            paddingRight: "8px", // Agregar relleno a la derecha
            boxSizing: "border-box" // Asegura que el padding no afecte el ancho total
          }}
        >
          {text}
        </div>
      ),
    },
  ];

  return (
    <div className="px-[15px]">
      <h3 className="font-bold text-lg pb-[24px]">Últimos inscritos</h3>
      <div style={{ maxHeight: "200px", overflowY: "auto" }}> {/* Contenedor de desplazamiento */}
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          rowClassName={(record, index) => (index % 2 === 0 ? "bg-[#F2F2F2]" : "bg-[#E6E6E6]")}
          scroll={{ y: 200 }} // Fijar el encabezado y permitir desplazamiento vertical
          className="custom-table" // Clase personalizada para estilos adicionales
        />
      </div>
    </div>
  );
};

export default LatestRegistrants;
