import React from "react";
import { Layout } from "antd";
import ButtonText from '../../button/ButtonText.tsx';
import 'tailwindcss/tailwind.css';

const { Sider } = Layout;

const SidebarOffers: React.FC = () => {
  // Define button text properties
  const buttonsOpenOffers = [
    { type: 'link', label: 'Reponedor de supermercado', size: 'small', textColor: '#006497', color: 'white'},
    { type: 'link', label: 'Mozo de almacén', size: 'small', textColor: '#006497', color: 'white' },
    { type: 'link', label: 'Administrativo', size: 'small', textColor: '#006497', color: 'white' },
  ];

  const buttonsClosedOffers = [
    { type: 'link', label: 'Reponedor de supermercado', size: 'small', textColor: '#006497', color: 'white' },
    { type: 'link', label: 'Mozo de almacén', size: 'small', textColor: '#006497', color: 'white' },
    { type: 'link', label: 'Administrativo', size: 'small', textColor: '#006497', color: 'white' },
  ];

  const buttonsSavedOffers = [
    { type: 'link', label: 'Cajero', size: 'small', textColor: '#006497', color: 'white' },
  ];

  const buttonsManageOffers = [
    { type: 'link', label: '¿Pricing?', size: 'small', textColor: '#006497', color: 'white'},
    { type: 'link', label: '¿Archivadas?', size: 'small', textColor: '#006497', color: 'white'},
    { type: 'link', label: '¿Compartidas?', size: 'small', textColor: '#006497', color: 'white'},
  ];

  return (
    <div className="flex min-h-screen">
      <Sider
        width={227}
        style={{ backgroundColor: "#fff" }}
        className="p-[24px] rounded-lg shadow-lg border-2 border-sky-blue0"
      >
        <h2 className="text-heading-md text-green42 font-bold mb-[30px]">Tus ofertas de empleo</h2>
        
        <div className="mb-6">
          <ButtonText
            buttons={[
              { 
                type: 'link', 
                label: 'Crear una nueva oferta de empleo', 
                size: 'small', 
                textColor: '#006497', 
                color: 'white', 
                fontWeight: 'bold', 
                fontSize: '14px', 
                minHeight: '40px' // Set specific minHeight for this button
              },
            ]}
            vertical
            gap={4}
          />
        </div>

        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">Ofertas abiertas</h3>
          <ButtonText buttons={buttonsOpenOffers} vertical/>
        </div>
        
        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">Ofertas finalizadas</h3>
          <ButtonText buttons={buttonsClosedOffers} vertical/>
        </div>
        
        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">Ofertas guardadas</h3>
          <ButtonText buttons={buttonsSavedOffers} vertical/>
        </div>

        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">Gestionar tus ofertas</h3>
          <ButtonText buttons={buttonsManageOffers} vertical/>
        </div>
      </Sider>
    </div>
  );
};

export default SidebarOffers;
