import React from 'react';
import { Checkbox } from 'antd';
import '../styles/checkboxProps/CheckboxProps.css';

interface StyledCheckboxProps {
  value: number;
  children: React.ReactNode;
  className?: string;
}

const StyledCheckbox: React.FC<StyledCheckboxProps> = ({ value, children, className }) => (
  <div className={className}>
    <Checkbox value={value} className="custom-checkbox">
      {children}
    </Checkbox>
  </div>
);

export default StyledCheckbox;
