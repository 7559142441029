import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Typography, Skeleton, Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import CardAlerts from "../../../components/pages/employment/yourAlerts/CardAlerts.tsx";
import SettingsComponent from "../../../components/pages/employment/yourAlerts/SidebarSettings.tsx";
import ButtonText from '../../../components/button/ButtonText.tsx';

const { Content, Sider } = Layout;
const { Text } = Typography;

const YourAlerts: React.FC = () => {
  const [loading, setLoading] = useState(true);

  // Estado para las alertas
  const [alerts, setAlerts] = useState([
    {
      title: "",
      subtitle: "",
      description: "",
      image: ""
    }
  ]);

  const initialAlerts = [
    {
      title: "Supermercados Carrefour",
      subtitle: "Reponedor de supermercado",
      description: "Importante cadena de alimentación busca incorporar un/a dependiente/a",
      image: ""
    },
    {
      title: "Supermercados Carrefour",
      subtitle: "Reponedor de supermercado",
      description: "Importante cadena de alimentación busca incorporar un/a dependiente/a",
      image: ""
    },
    {
      title: "Supermercados Carrefour",
      subtitle: "Reponedor de supermercado",
      description: "Importante cadena de alimentación busca incorporar un/a dependiente/a",
      image: ""
    },
    {
      title: "Supermercados Carrefour",
      subtitle: "Reponedor de supermercado",
      description: "Importante cadena de alimentación busca incorporar un/a dependiente/a",
      image: ""
    },
    {
      title: "Supermercados Carrefour",
      subtitle: "Reponedor de supermercado",
      description: "Importante cadena de alimentación busca incorporar un/a dependiente/a",
      image: ""
    },
    {
      title: "Supermercados Carrefour",
      subtitle: "Reponedor de supermercado",
      description: "Importante cadena de alimentación busca incorporar un/a dependiente/a",
      image: ""
    },
    {
      title: "Supermercados Carrefour",
      subtitle: "Reponedor de supermercado",
      description: "Importante cadena de alimentación busca incorporar un/a dependiente/a",
      image: ""
    },
    {
      title: "Supermercados Carrefour",
      subtitle: "Reponedor de supermercado",
      description: "Importante cadena de alimentación busca incorporar un/a dependiente/a",
      image: ""
    }
  ];

  // Estados separados para controlar el texto del botón y el número de alertas visibles por cada bloque
  const [buttonTexts, setButtonTexts] = useState(["Ver todas", "Ver todas", "Ver todas"]);
  const [visibleAlerts, setVisibleAlerts] = useState([4, 4, 4]);

  // Estados de carga por sección
  const [sectionLoading, setSectionLoading] = useState([false, false, false]);
  const [newLoading, setNewLoading] = useState([false, false, false]);

  // Estado para controlar la visibilidad del Drawer
  const [drawerVisible, setDrawerVisible] = useState(false);

  // Función para manejar el clic del botón para cada sección
  const handleButtonClick = (index: number) => {
    const newVisibleAlerts = [...visibleAlerts];
    const newButtonTexts = [...buttonTexts];
    const newSectionLoading = [...sectionLoading];
    const newNewLoading = [...newLoading];

    if (newVisibleAlerts[index] === 4) {
      newNewLoading[index] = true; // Activa el efecto de carga para las nuevas alertas
      setNewLoading(newNewLoading);
      newSectionLoading[index] = true; // Activa el efecto de carga para la sección

      setTimeout(() => {
        newVisibleAlerts[index] = alerts.length; // Muestra todas las alertas de esa sección
        newButtonTexts[index] = "Ver menos"; // Cambia el texto del botón
        newSectionLoading[index] = false; // Desactiva el efecto de carga para la sección
        newNewLoading[index] = false; // Desactiva el efecto de carga para las nuevas alertas
        setVisibleAlerts(newVisibleAlerts);
        setButtonTexts(newButtonTexts);
        setSectionLoading(newSectionLoading);
        setNewLoading(newNewLoading);
      }, 1000); // Simula un tiempo de carga de 1 segundo
    } else {
      newVisibleAlerts[index] = 4; // Muestra solo 4 alertas de esa sección
      newButtonTexts[index] = "Ver todas"; // Cambia el texto del botón
      setVisibleAlerts(newVisibleAlerts);
      setButtonTexts(newButtonTexts);
    }
  };

  // Simulate initial data fetching
  useEffect(() => {
    setTimeout(() => {
      setAlerts(initialAlerts);
      setLoading(false);
    }, 2000); // Simulate a 2-second loading time
  }, []);

  // Añadimos un array con la cantidad de alertas para cada sección
  const alertsCount = [24, 44, 44];

  const renderAlertSection = (sectionIndex: number, titleText: string, titleColor: string, alertCount: number) => (
    <>
      <Row className="mt-6">
        <Col span={24} className="flex justify-between items-center">
          <div>
            {/* Muestra el número de alertas */}
            <Text className="text-heading-x1 font-bold text-blue3">{alertCount}</Text> {/* Cambia el color y tamaño aquí */}
            {/* Muestra el texto de la sección */}
            <Text className="text-heading-x1 font-medium ml-2" style={{ color: titleColor }}>{titleText}</Text>
          </div>
          <ButtonText
            buttons={[
              {
                type: 'link',
                label: buttonTexts[sectionIndex],
                size: 'small',
                textColor: '#006497',
                color: 'white',
                fontWeight: 600, // Semibold
                fontSize: "14px",
                onClick: () => handleButtonClick(sectionIndex) // Pasa el índice correspondiente
              }
            ]}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt-4">
        {loading
          ? Array(4).fill(null).map((_, index) => (
              <Col key={`loading-${index}`} xs={24} sm={24} md={12} lg={12} xl={12}>
                <Skeleton active paragraph={{ rows: 4 }} />
              </Col>
            ))
          : alerts.slice(0, 4).map((alert, index) => (
              <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                <CardAlerts
                  title={alert.title}
                  subtitle={alert.subtitle}
                  description={alert.description}
                  image={alert.image}
                />
              </Col>
            ))}

        {newLoading[sectionIndex]
          ? Array(4).fill(null).map((_, index) => (
              <Col key={`new-loading-${index}`} xs={24} sm={24} md={12} lg={12} xl={12}>
                <Skeleton active paragraph={{ rows: 4 }} />
              </Col>
            ))
          : alerts.slice(4, visibleAlerts[sectionIndex]).map((alert, index) => (
              <Col key={`alert-${index + 4}`} xs={24} sm={24} md={12} lg={12} xl={12}>
                <CardAlerts
                  title={alert.title}
                  subtitle={alert.subtitle}
                  description={alert.description}
                  image={alert.image}
                />
              </Col>
            ))}
      </Row>
    </>
  );

  return (
    <Layout className="bg-white">
      <Content className="flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-heading-sm font-bold">Alertas activas</h1>
          {/* Botón de menú de hamburguesa */}
          <Button
            type="primary"
            className="lg:hidden" // Mostrar solo en pantallas pequeñas
            onClick={() => setDrawerVisible(true)}
            icon={<MenuOutlined />}
          />
        </div>

        {renderAlertSection(0, "de Reponedor en Campo de Criptana", "#5F5F5F", alertsCount[0])}
        {renderAlertSection(1, "de Diseñador UX en Toda España", "#5F5F5F", alertsCount[1])}
        {renderAlertSection(2, "de Palabras clave", "#5F5F5F", alertsCount[2])}
      </Content>

      {/* Sidebar visible en pantallas grandes */}
      <Sider
        width={234}
        breakpoint="lg"
        collapsedWidth="0"
        style={{ backgroundColor: 'white' }}
        className="hidden lg:block ml-4" // Ocultar en pantallas pequeñas
      >
        <SettingsComponent />
      </Sider>

      {/* Drawer para pantallas pequeñas */}
      <Drawer
        title="Ajustes"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        className="lg:hidden" // Mostrar solo en pantallas pequeñas
      >
        <SettingsComponent />
      </Drawer>
    </Layout>
  );
};

export default YourAlerts;
