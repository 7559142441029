import React, { useState } from "react";
import { Checkbox } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CheckboxC from "../../../../../components/checkbox/CheckboxProps.tsx"; // Asegúrate de que la ruta sea correcta

type CheckQuestionProps = {
    options: string[]; // Definimos que el componente recibirá un array de strings como props
    onAddOption: () => void; // Función que se llamará al agregar una nueva opción
  };
  
  const CheckQuestion: React.FC<CheckQuestionProps> = ({ options, onAddOption }) => {
    // Mantener el estado de cada checkbox individualmente
    const [checkedItems, setCheckedItems] = useState<boolean[]>(new Array(options.length).fill(false));
  
    // Manejar el cambio en el estado de los checkboxes
    const handleCheckboxChange = (index: number) => {
      const newCheckedItems = [...checkedItems];
      newCheckedItems[index] = !newCheckedItems[index]; // Cambiar el estado de la casilla seleccionada
      setCheckedItems(newCheckedItems);
    };
  
    return (
      <div>
        <div className="flex flex-wrap">
          {options.map((option, index) => (
            <div key={index} className="flex items-center mb-3 mr-5">
              <CheckboxC
                checked={checkedItems[index]}
                onChange={() => handleCheckboxChange(index)}
              />
              <span className="ml-1 font-bold text-body-md mr-[15px]">{option}</span>
            </div>
          ))}
        </div>
        <h1 className="mt-2 pl-[3px] text-gray cursor-pointer w-[240px]" onClick={onAddOption}>
          <PlusOutlined className="text-blue3 text-body-sm pr-[8px]" />
          Añadir nueva casilla
        </h1>
      </div>
    );
  };
  
  export default CheckQuestion;