import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Select, Row, Col } from 'antd';
import IconSearch from '../../../assets/icons/search.svg';
import '../../../styles/pages/employment/Employment.css';
import '../../../styles/pages/employment/FiltersEmployment.css';
import FilterComponent from "../../../components/pages/employment/searchResult/SidebarFilter.tsx";
import './Company.css';
import CardEmpresa from '../../../components/cards/CardCompany.tsx';
import Header from '../../../components/pages/principalNav/PrincipalNav.tsx';

const { Option } = Select;

const Company: React.FC = () => {
  const navigate = useNavigate();

  const companies = [
    {
      id: '1',
      title: "Supermercados Carrefour",
      description: "Retail • Alimentación y bebidas",
      location: "Madrid, Spain",
      link: "https://carrefour.es",
      avatarUrl: "https://popgroup.global/wp-content/uploads/2017/11/carrefour-logo-1.png",
      reviews: 20,
      activeOffers: 90,
      followers: 450,
      workers: 500,
      categories: ['Administrativo', 'Reponedor', 'Ventas']
    },
    {
      id: '2',
      title: "Mercadona",
      description: "Retail • Alimentación y bebidas",
      location: "Madrid, Spain",
      link: "https://carrefour.es",
      avatarUrl: "https://popgroup.global/wp-content/uploads/2017/11/carrefour-logo-1.png",
      reviews: 20,
      activeOffers: 90,
      followers: 450,
      workers: 500,
      categories: ['Administrativo', 'Reponedor', 'Ventas']
    },
    // Agrega más empresas aquí...
  ];

  const handleCardClick = (id: string) => {
    navigate(`/companyDetail/${id}`);
  };

  return (
    <>
      <div>
        <Header />
      </div>
      <div className="company-container ml-[24px] mr-[24px]">
        <div className="bg-pink-box"></div>
        
        <Row>
          <Col xl={12} md={12}>
            <span className="text-2xl font-bold company-text text-center md:text-left mb-4 md:mb-0">
              Sigue a las empresas que más te<br />interesen del sector
            </span>
          </Col>
          <Col xl={12} md={12}>
            <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right' }}>
              <Input
                prefix={<img src={IconSearch} width={24} height={24} />}
                placeholder="Buscar empleo de..."
                className="Input-Filter-Employment w-full md:w-[417px] custom-input-company px-2 rounded"
              />
              <Select
                placeholder={<div>
                  <span
                    style={{fontSize: '18px', fontWeight: '600'}}
                  >Sector </span>(Opcional)
                </div>}
                className="custom-select-company px-2 rounded Select-Filter-Employment w-full md:w-[225px] mt-4 md:mt-0 ml-[8px]"
                style={{ textAlign: 'left' }}
              >
                <Option value="sector1">Sector 1</Option>
                <Option value="sector2">Sector 2</Option>
                <Option value="sector3">Sector 3</Option>
              </Select>
              <Button
                className="custom-button-company px-4 rounded w-full md:w-auto mt-4 md:mt-0 ml-[8px]"
              >
                Buscar
              </Button>
            </div>
          </Col>
        </Row>

        <p className="text-gray-700 font-bold text-lg mb-8 text-center md:text-left mt-[55px]">
          Busca o filtra entre empresas reclutadoras por "valoraciones", "sector", "palabras clave"...
        </p>

        <Row>
          <Col xxl={4} xl={5} md={6} sm={8} >
            <FilterComponent />
          </Col>
          <Col xxl={20} xl={19} md={18} sm={16} >
            {companies.map(company => (
              <CardEmpresa
                key={company.id}
                title={company.title}
                description={company.description}
                location={company.location}
                link={company.link}
                avatarUrl={company.avatarUrl}
                reviews={company.reviews}
                activeOffers={company.activeOffers}
                followers={company.followers}
                workers={company.workers}
                categories={company.categories}
                cardType={4}
                onClick={() => handleCardClick(company.id)}
                className="mb-4" // Añadido margen inferior
              />
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Company;