import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Button } from 'antd'; // Importa el componente Button de Ant Design
import logo from '../../../assets/logo/logo.png';
import IconNotification from '../../../assets/icons/notification.svg';
import '../../styles/pages/principalNav/PrincipalNav.css';
import Employment from '../../../pages/employment/Employment.tsx';
import Company from '../../../pages/company/company/Company.tsx';
import MyApplications from '../../../pages/myApplications/MyApplications.tsx';
import Portal from '../../../pages/myPortal/MyPortal.tsx'; // Actualiza esta ruta según la ubicación correcta de tu componente Portal
import Home from '../../../pages/home/Home.tsx';

const PrincipalNav = () => {
  // Obtener la ubicación actual
  const location = useLocation();

  // Función para verificar si una ruta está activa
  const isActive = (path) => {
    return location.pathname.startsWith(path);
  };

  return (
    <div className="principal-nav">
      <header className="principal-nav-header">
        <Link to="/home">
          <img src={logo} alt="Merere Logo" className="principal-nav-logo mb-4" />
        </Link>
        <nav className="principal-nav-nav">
          <Link
            to="/employment/recommendations"
            className={`principal-nav-link ${isActive('/employment') ? 'active' : ''}`}
          >
            Empleo
          </Link>
          <Link
            to="/company"
            className={`principal-nav-link ${isActive('/company') ? 'active' : ''}`}
          >
            Empresas
          </Link>
          <Link
            to="/myApplications/applications"
            className={`principal-nav-link ${isActive('/myApplications') ? 'active' : ''}`}
          >
            Mis Candidaturas
          </Link>
          <Link
            to="/myPortal/profile"
            className={`principal-nav-link ${isActive('/myPortal') ? 'active' : ''}`}
          >
            Mi Portal
          </Link>
        </nav>
        <div className="principal-nav-action-buttons flex space-x-2">
          <Button className="principal-nav-bell-button" icon={<img src={IconNotification} alt="Notification" />} />
          <Button className="principal-nav-notify-button">Permite que te valoren</Button>
        </div>
      </header>

      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/employment" element={<Employment />} />
        <Route path="/company" element={<Company />} />
        <Route path="/myApplications" element={<MyApplications />} />
        <Route path="/myPortal" element={<Portal />} />
      </Routes>
    </div>
  );
};

export default PrincipalNav;

