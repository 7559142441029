import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ExampleChipPage from './examples/chip/Chip.tsx';
import ExampleCheckbox from './examples/checkbox/Checkbox.tsx';
import ExampleBadge from './examples/badge/Badge.tsx';
import SidebarExample from './examples/sidebarProfile/SidebarProfile.tsx';
import HomeCompany from './pages/homeCompany/HomeCompany.tsx';
import ExampleTabs from './examples/tabs/Tabs.tsx';
import CardUserExample from './examples/cards/CardUser.tsx';
import CardEmpresaExample from './examples/cards/CardCompany.tsx';
import Home from './pages/home/Home.tsx';
import ChartDonutExample from './examples/chartDonut/ChartDonut.tsx';
import CardEmpleoExample from './examples/cards/CardEmployment.tsx';
import ModalsExample from './examples/modals/Modals.tsx';
import EmpleoRecomendaciones from './pages/employment/Employment.tsx';
import Candidaturas from './pages/myApplications/MyApplications.tsx';
import LegendGroupExample from './examples/legendGroup/LegendGroup.tsx';
import Button from './examples/button/Button.tsx';
import ButtonIcon from './examples/button/ButtonIcon.tsx';
import ButtonText from './examples/button/ButtonText.tsx';
import ToggleSwitch from './examples/toggleSwitch/ToggleSwitch.tsx';
import AppExample from './examples/dropdownInput/DropdownInput.tsx';
import AppExampleAvatar from './examples/avatarLabel/AvatarLabel.tsx';
import ExampleAvatar from './examples/avatar/Avatar.tsx';
import RatingExample from './examples/rating/Rating.tsx';
import Sidebar from './layout/Sidebar.tsx';
import Gratitude from './examples/gratitude/Gratitude.tsx';
import CompanyDetail from './pages/company/companyDetail/CompanyDetail.tsx';
import MyPortal from './pages/myPortal/MyPortal.tsx';
import Company from './pages/company/company/Company.tsx';
import ControlPanel from './pages/controlPanel/ControlPanel.tsx';
import SearchCV from './pages/searchCV/SearchCV.tsx';
import Offers from './pages/offers/Offers.tsx';
import OffersProfile from './pages/offers/offersProfile/OffersProfile.tsx';
import CreateOffers from './pages/offers/CreateOffer/CreateOffer.tsx'; 
import KillerQuestions from './pages/offers/KillerQuestions/KillerQuestions.tsx'; 
import Example2Checkbox from './components/checkbox/CheckboxProps.tsx';
import './App.css';
import CardExample from './components/pages/employment/yourAlerts/CardAlerts.tsx';
import Settings from './components/pages/employment/yourAlerts/SidebarSettings.tsx';
import ResultExample from './components/pages/employment/searchResult/SidebarFilter.tsx';
import AlertFilter from './components/pages/employment/searchResult/InitialInfo.tsx';
import ExamplePerfil from './pages/myPortal/profile/Profile.tsx'
import SettingExample from './pages/myPortal/generalSettings/GeneralSettings.tsx'
import HelpExample from './pages/myPortal/help/Help.tsx'

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/homeCompany" element={<HomeCompany />} />
      <Route path="/home" element={<Home />} />
      <Route path="/gratitude" element={<Gratitude />} />
      <Route path="/sidebar" element={<Sidebar />} />
      <Route path="/employment/*" element={<EmpleoRecomendaciones />} />
      <Route path="/companyDetail/:id" element={<CompanyDetail />} />
      <Route path='/company/*' element={<Company/>} />
      <Route path="/myApplications/*" element={<Candidaturas />} />
      <Route path="/myPortal/*" element={<MyPortal />} />
      <Route path="/controlPanel/*" element={<ControlPanel />} />
      <Route path="/searchCV" element={<SearchCV />} />
      <Route path="/offers" element={<Offers />} />
      <Route path="/offers/:id" element={<OffersProfile />} />
      <Route path="/offers/" element={<Offers />} />
      <Route path="offers/createOffer/" element={<CreateOffers />} />
      <Route path="offers/killerQuestions/" element={<KillerQuestions />} />
      <Route path="checkboxProps" element={<Example2Checkbox />} />

  

      {/* Agrupar todas las rutas de ejemplos bajo el Sidebar */}
      <Route path="/examples/*" element={<Sidebar />}>
        <Route path="chip" element={<ExampleChipPage />} />
        <Route path="checkbox" element={<ExampleCheckbox />} />
        <Route path="badge" element={<ExampleBadge />} />
        <Route path="tabs" element={<ExampleTabs />} />
        <Route path="card-user" element={<CardUserExample />} />
        <Route path="card-company" element={<CardEmpresaExample />} />
        <Route path="chart-donut-example" element={<ChartDonutExample />} />
        <Route path="card-employment-example" element={<CardEmpleoExample />} />
        <Route path="legendGroup" element={<LegendGroupExample />} />
        <Route path="Buttons" element={<Button />} />
        <Route path="ButtonsIcon" element={<ButtonIcon />} />
        <Route path="ButtonsText" element={<ButtonText />} />
        <Route path="ToggleSwitch" element={<ToggleSwitch />} />
        <Route path="AppExample" element={<AppExample />} />
        <Route path="AppExampleAvatar" element={<AppExampleAvatar />} />
        <Route path="ExampleAvatar" element={<ExampleAvatar />} />
        <Route path="RatingExample" element={<RatingExample />} />
        <Route path="modals" element={<ModalsExample />} />
        <Route path="sidebarExample" element={<SidebarExample />} />
      </Route>
    </Routes>
  );
};

export default App;
