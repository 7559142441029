import React from 'react';
import { Row, Col } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import ImgLogo from '../../assets/logo/logo.png';
import IconEnterprise from '../../assets/icons/enterprise.svg';

const CardTopHome: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isCompanyView = location.pathname === '/homeCompany';

  const handleSwitchToCompanyView = () => {
    navigate('/homeCompany');
  };

  const handleSwitchToHomeView = () => {
    navigate('/');
  };

  const commonStyles = {
    button: {
      height: '44px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginBottom: '10px',
    },
    container: {
      marginTop: '20px',
      marginRight: '23px',
      marginLeft: '23px',
      alignItems: 'center',
      paddingLeft: '24px',
      paddingRight: '24px',
      height: '68px',
      flexWrap: 'wrap',
      display: 'flex',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      boxShadow: '0px 4px 2px -2px rgba(0, 0, 0, 0.1)',
    },
  };

  return (
    <div>
      {isCompanyView ? (
        <div style={commonStyles.container}>
          <Row style={{ width: '100%' }}>
            <Col xl={6} md={6} xs={24} style={{ textAlign: 'center', marginBottom: '10px' }}>
              <img src={ImgLogo} style={{ width: '140px', height: '54px', marginTop: '-5px' }} />
            </Col>
            <Col xl={8} md={8} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', fontFamily: 'Inter', textAlign: 'center', marginBottom: '10px', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              <span style={{ cursor: 'pointer', padding: '0 40px', marginRight: '5px' }} onClick={() => navigate('/controlPanel')}>Buscar talento</span>
            </Col>
            <Col xl={10} md={10} xs={24} style={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 600, display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: '12px' }}>
              <div style={{ ...commonStyles.button, width: '165px', background: '#006497', color: 'white' }} onClick={() => navigate('/company')}>
                ÁREA DE EMPRESA 
              </div>
              <div style={{ ...commonStyles.button, width: '190px', background: 'white', color: '#006497', border: '1px solid #006497' }} onClick={handleSwitchToHomeView}>
                En búsqueda de empleo
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={commonStyles.container}>
          <Row style={{ width: '100%' }}>
            <Col xl={6} md={6} xs={24} style={{ textAlign: 'center', marginBottom: '10px' }}>
              <img src={ImgLogo} style={{ width: '140px', height: '54px', marginTop: '-5px' }} />
            </Col>
            <Col xl={8} md={8} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', fontFamily: 'Inter', textAlign: 'center', marginBottom: '10px', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              <span style={{ cursor: 'pointer', padding: '0 40px', marginRight: '5px' }} onClick={() => navigate('/employment/recommendations')}>Empleo</span>
              <span style={{ cursor: 'pointer', padding: '0 0' }} onClick={() => navigate('/company')}>Empresas</span>
            </Col>
            <Col xl={10} md={10} xs={24} style={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 600, display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: '12px' }}>
              <div style={{ ...commonStyles.button, width: '165px', background: '#006497', color: 'white' }} onClick={handleSwitchToCompanyView}>
                <img src={IconEnterprise} style={{ width: '18px', marginRight: '5px' }} />
                Soy una empresa
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default CardTopHome;
