import React from 'react';
import {
  CheckOutlined,
  ShoppingOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  SafetyCertificateTwoTone,
} from '@ant-design/icons';
import { Avatar, Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Meta } = Card;

interface CardEmpresaProps {
  title: string;
  description: string;
  location: string;
  link: string;
  avatarUrl: string;
  reviews: number;
  activeOffers: number;
  followers: number;
  workers: number;
  categories: string[];
  cardType?: number;
  onClick: () => void;
  className?: string;
}

const getCardStyle = (cardType?: number): React.CSSProperties => {
  switch (cardType) {
    case 2:
      return { border: '1px solid #81BFEC' };
    case 3:
      return { border: '1px solid #81BFEC' };
    case 4:
      return { backgroundColor: '#FCFCFC', border: 'none', width: '100%', height:'160px' };
    case 1:
    default:
      return { border: '1px solid #d9d9d9' };
  }
};

const CardEmpresa: React.FC<CardEmpresaProps> = ({
  title,
  description,
  location,
  link,
  avatarUrl,
  reviews,
  activeOffers,
  followers,
  workers,
  categories,
  cardType,
  onClick,
  className,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick();
  };

  return (
    <Card
      onClick={handleClick}
      className={`p-5 bg-white overflow-hidden relative ${className} shadow-md hover:shadow-lg`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        borderRadius: '0',
        height: 'auto',
        ...getCardStyle(cardType),
      }}
    >
      <Meta
        avatar={<Avatar className="w-24 h-24 mb-2 md:mb-0" src={avatarUrl} />}
        title={
          <div className="flex items-center justify-between w-full -mb-1">
            <span className="text-lg font-bold text-black">{title}</span>
          </div>
        }
        description={
          <div className="text-sm text-black">
            {description} • {location} • Valoraciones • +{workers} trabajadores
            <br />
            <a className="text-sm font-bold text-[#666666]" href={link}>{link}</a>
            <div className="flex mt-2 overflow-hidden space-x-1 flex-wrap">
              <Button
                className="bg-white border border-gray-300 rounded-full flex-none"
                icon={<CheckOutlined className="text-blue4 bg-white" />}
              >
                {reviews} valoraciones
              </Button>
              <Button
                className="bg-white border border-gray-300 rounded-full flex-none"
                icon={<ShoppingOutlined className="text-blue4 bg-white" />}
              >
                {activeOffers} Ofertas activas
              </Button>
              <Button
                className="bg-white border border-gray-300 rounded-full flex-none"
                icon={<UsergroupDeleteOutlined className="text-blue4 bg-white" />}
              >
                {followers} Seguidores
              </Button>
              <Button
                className="bg-white border border-gray-300 rounded-full flex-none"
                icon={<UsergroupAddOutlined className="text-blue4 bg-white" />}
              >
                +{workers} trabajadores
              </Button>
            </div>
          </div>
        }
      />
      <SafetyCertificateTwoTone className="absolute top-4 right-7 text-2xl hidden md:block" />
    </Card>
  );
};

export default CardEmpresa;
