// MyPortal.tsx
import React, { useState } from "react";
import { Row, Col } from "antd";
import { Routes, Route } from "react-router-dom";
import Header from "../../components/pages/principalNav/PrincipalNav.tsx";
import SidebarProfile from "../../components/sidebarProfile/SidebarProfile.tsx";
import HelpExample from "../../pages/myPortal/help/Help.tsx";
import SettingExample from "../../pages/myPortal/generalSettings/GeneralSettings.tsx";
import ProfileExample from "../../pages/myPortal/profile/Profile.tsx";
import profilePicture from "../../assets/img/MyPortal/ImageP.svg";
// Importar la imagen de encabezado
import headerImage from "../../assets/img/MyPortal/imageS.svg"; // Cambia la ruta según la ubicación de tu imagen

const MyPortal: React.FC = () => {
  const [isJobSearchActive, setIsJobSearchActive] = useState(true);

  const handleJobSearchToggle = (checked: boolean) => {
    setIsJobSearchActive(checked);
  };

  const sidebarData = {
    profilePicture: profilePicture,
    name: "Elena Rodriguez",
    jobTitle: "Vendedora",
    location: "Paterna, Valencia",
  };

  return (
    <>
      <Header />
      {/* Imagen de encabezado */}
      <div className="relative w-full px-7">
        <img
          src={headerImage}
          alt="Header"
          className="w-full h-64 object-cover rounded-lg"
        />
      </div>

      <div className="bg-white p-6">
        <div className="flex">
          <div style={{ width: "234px" }} className="px-2 bg-white">
            <SidebarProfile
              profilePicture={sidebarData.profilePicture}
              name={sidebarData.name}
              jobTitle={sidebarData.jobTitle}
              location={sidebarData.location}
              isJobSearchActive={isJobSearchActive}
              onJobSearchToggle={handleJobSearchToggle}
              width={234}
            />
          </div>

          <div className="flex-1 pl-6 pr-1">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24}>
                {/* Mueve los Routes aquí para centralizar la navegación */}
                <Routes>
                  <Route path="profile" element={<ProfileExample />} />
                  <Route path="help" element={<HelpExample />} />
                  <Route path="generalSettings" element={<SettingExample />} />
                </Routes>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPortal;
