import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import "tailwindcss/tailwind.css";
import CardEmpleo from "../../../components/cards/CardEmployment.tsx";
import Information from "../../../components/pages/employment/recommendations/Information.tsx";
import FilterComponent from "../../../components/pages/employment/searchResult/SidebarFilter.tsx";
import AlertFilter from '../../../components/pages/employment/searchResult/InitialInfo.tsx';

const SearchResult: React.FC = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const [appliedCards, setAppliedCards] = useState<{ [key: number]: boolean }>({});
  const [loadingCards, setLoadingCards] = useState<{ [key: number]: boolean }>({ 0: true, 1: true, 2: true });
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);

  const cardsData = [
    {
      exampleProps: {
        companyName: "Supermercados Carrefour",
        jobTitle: "Reponedor de supermercado",
        location: "Campo de Criptana",
        salary: "18.000€",
        schedule: "Parcial",
        ratings: "20 valoraciones",
        activeOffers: "90 ofertas activas",
        followers: "450 Seguidores",
        description: "Importante cadena de alimentación busca incorporar un/a dependiente/a reponedor/a en Fuerteventura.<br>Requisitos: Experiencia en la caja y reposicion, Manejo transpaleta manual, Horario: L-V de 8-15h o 13-20h y S 9-14h(rotativo)...",
        postedTime: "Subida hace 23h",
        applied: false,
      },
      informationProps: {
        title: "Reponedor de supermercado",
        company: "Supermercados Carrefour",
        location: "Campo de Criptana",
        employmentType: "Parcial",
        salary: "18.000€",
        comments: 20,
        applicantsCount: "Inscritos",
        introText: "En Carrefour consideramos que todas las personas tienen un talento que ofrecer. Buscamos personas que empaticen con los clientes, compartan nuestros valores y estén dispuestas a probar nuevos retos. Si desbordas energía, te gusta que todo esté en su sitio y te encanta trabajar codo con codo con tus compañeros, trae tu talento a LOGÍSTICA.",
        requirements: [
          "Inglés: Nivel B1",
          "Incorporación: inmediata",
          "Jornada: Tiempo parcial, mañana",
          "Qué harás día a día",
        ],
        responsibilities: [
          "Cargar y descargar las mercancías de los camiones, comprobar su correcto estado, y chequear los correspondientes albaranes para evitar posibles errores.",
          "Poner la mercancía a disposición del cliente en su correspondiente punto de venta en la tienda, así como realizar las devoluciones de mercancía al almacén en caso de que no sea necesaria en el punto de venta, con el objetivo de garantizar el stock de mercancía en el punto de ventas.",
          "Estás disponible para ayudar a los compañeros del departamento y otras áreas de la tienda cuando sea necesario.",
          "Te comprometes con todas las reglas y requisitos de Carrefour en materia de salud, seguridad y protección, y ayudas activamente a los compañeros a evitar cualquier incidente en el que puedan verse implicados colaboradores, clientes, equipos o instalaciones.",
        ],
        extraText: [
          "Porque formas parte de la gran familia CARREFOUR queremos compartir contigo los momentos importantes, por ello celebramos aniversarios, fiestas, Teams Buildings, la llegada del verano, regalo de Navidad. ¡Siempre hay un motivo para sonreír!",
        ],
        extraText2: ["¡Muchos más te están esperando, ¡descúbrelos!"],
        postedTime: "Subida hace 23h",
      },
    },
    {
      exampleProps: {
        companyName: "Supermercados Carrefour",
        jobTitle: "Reponedor de supermercado",
        location: "Campo de Criptana",
        salary: "18.000€",
        schedule: "Parcial",
        ratings: "20 valoraciones",
        activeOffers: "90 ofertas activas",
        followers: "450 Seguidores",
        description: "Importante cadena de alimentación busca incorporar un/a dependiente/a reponedor/a en Fuerteventura.<br>Requisitos: Experiencia en la caja y reposicion, Manejo transpaleta manual, Horario: L-V de 8-15h o 13-20h y S 9-14h(rotativo)...",
        postedTime: "Subida hace 23h",
        applied: false,
      },
      informationProps: {
        title: "Reponedor de supermercado",
        company: "Supermercados Carrefour",
        location: "Campo de Criptana",
        employmentType: "Parcial",
        salary: "18.000€",
        comments: 20,
        applicantsCount: "Inscritos",
        introText: "En Carrefour consideramos que todas las personas tienen un talento que ofrecer. Buscamos personas que empaticen con los clientes, compartan nuestros valores y estén dispuestas a probar nuevos retos. Si desbordas energía, te gusta que todo esté en su sitio y te encanta trabajar codo con codo con tus compañeros, trae tu talento a LOGÍSTICA.",
        requirements: [
          "Inglés: Nivel B1",
          "Incorporación: inmediata",
          "Jornada: Tiempo parcial, mañana",
          "Qué harás día a día",
        ],
        responsibilities: [
          "Cargar y descargar las mercancías de los camiones, comprobar su correcto estado, y chequear los correspondientes albaranes para evitar posibles errores.",
          "Poner la mercancía a disposición del cliente en su correspondiente punto de venta en la tienda, así como realizar las devoluciones de mercancía al almacén en caso de que no sea necesaria en el punto de venta, con el objetivo de garantizar el stock de mercancía en el punto de ventas.",
          "Estás disponible para ayudar a los compañeros del departamento y otras áreas de la tienda cuando sea necesario.",
          "Te comprometes con todas las reglas y requisitos de Carrefour en materia de salud, seguridad y protección, y ayudas activamente a los compañeros a evitar cualquier incidente en el que puedan verse implicados colaboradores, clientes, equipos o instalaciones.",
        ],
        extraText: [
          "Porque formas parte de la gran familia CARREFOUR queremos compartir contigo los momentos importantes, por ello celebramos aniversarios, fiestas, Teams Buildings, la llegada del verano, regalo de Navidad. ¡Siempre hay un motivo para sonreír!",
        ],
        extraText2: ["¡Muchos más te están esperando, ¡descúbrelos!"],
        postedTime: "Subida hace 23h",
      },
    },
    {
      exampleProps: {
        companyName: "Supermercados Carrefour",
        jobTitle: "Reponedor de supermercado",
        location: "Campo de Criptana",
        salary: "18.000€",
        schedule: "Parcial",
        ratings: "20 valoraciones",
        activeOffers: "90 ofertas activas",
        followers: "450 Seguidores",
        description: "Importante cadena de alimentación busca incorporar un/a dependiente/a reponedor/a en Fuerteventura.<br>Requisitos: Experiencia en la caja y reposicion, Manejo transpaleta manual, Horario: L-V de 8-15h o 13-20h y S 9-14h(rotativo)...",
        postedTime: "Subida hace 23h",
        applied: false,
      },
      informationProps: {
        title: "Reponedor de supermercado",
        company: "Supermercados Carrefour",
        location: "Campo de Criptana",
        employmentType: "Parcial",
        salary: "18.000€",
        comments: 20,
        applicantsCount: "Inscritos",
        introText: "En Carrefour consideramos que todas las personas tienen un talento que ofrecer. Buscamos personas que empaticen con los clientes, compartan nuestros valores y estén dispuestas a probar nuevos retos. Si desbordas energía, te gusta que todo esté en su sitio y te encanta trabajar codo con codo con tus compañeros, trae tu talento a LOGÍSTICA.",
        requirements: [
          "Inglés: Nivel B1",
          "Incorporación: inmediata",
          "Jornada: Tiempo parcial, mañana",
          "Qué harás día a día",
        ],
        responsibilities: [
          "Cargar y descargar las mercancías de los camiones, comprobar su correcto estado, y chequear los correspondientes albaranes para evitar posibles errores.",
          "Poner la mercancía a disposición del cliente en su correspondiente punto de venta en la tienda, así como realizar las devoluciones de mercancía al almacén en caso de que no sea necesaria en el punto de venta, con el objetivo de garantizar el stock de mercancía en el punto de ventas.",
          "Estás disponible para ayudar a los compañeros del departamento y otras áreas de la tienda cuando sea necesario.",
          "Te comprometes con todas las reglas y requisitos de Carrefour en materia de salud, seguridad y protección, y ayudas activamente a los compañeros a evitar cualquier incidente en el que puedan verse implicados colaboradores, clientes, equipos o instalaciones.",
        ],
        extraText: [
          "Porque formas parte de la gran familia CARREFOUR queremos compartir contigo los momentos importantes, por ello celebramos aniversarios, fiestas, Teams Buildings, la llegada del verano, regalo de Navidad. ¡Siempre hay un motivo para sonreír!",
        ],
        extraText2: ["¡Muchos más te están esperando, ¡descúbrelos!"],
        postedTime: "Subida hace 23h",
      },
    },
    
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingCards({ 0: false, 1: false, 2: false });
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleCardClick = (index: number) => {
    setLoadingInfo(true);
    setSelectedCardIndex(index);

    setTimeout(() => {
      setLoadingInfo(false);
    }, 2000);
  };

  const handleApply = (index: number | null) => {
    if (index !== null) {
      setAppliedCards((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    }
  };

  const handleCreateAlertClick = () => {
    alert('Nueva alerta creada');
  };

  return (
    <div className="bg-white">
      <div className="pl-0">
        <AlertFilter
          newOffersCount={68}
          onCreateAlertClick={handleCreateAlertClick}
          newOffersText="Nuevas ofertas que se adaptan a ti"
          createAlertText="Crear nueva alerta"
        />
      </div>
      <div className="flex">
        <div
          style={{ width: "234px", backgroundColor: "white" }}
          className="p-0"
        >
          <FilterComponent />
        </div>
        <div className="flex-1 px-4">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24}>
              {cardsData.map((card, index) => (
                <div
                  key={index}
                  onClick={() => handleCardClick(index)}
                  className={`cursor-pointer mb-5 p-4 rounded-lg transition-shadow duration-300 ${
                    selectedCardIndex === index
                      ? "shadow-lg"
                      : "hover:shadow-md"
                  }`}
                >
                  {selectedCardIndex === index ? (
                    <Information
                      {...card.informationProps}
                      applied={!!appliedCards[index]}
                      onApply={() => handleApply(index)}
                      loading={loadingInfo}
                      partialLoading={true}
                    />
                  ) : (
                    <CardEmpleo
                      {...card.exampleProps}
                      applied={!!appliedCards[index]}
                      loading={loadingCards[index]}
                    />
                  )}
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
